import styled from '@emotion/styled';
import {
  CASTING_STATUS,
  CASTING_STATUS_DISPLAY_TEXT_DICT,
  Casting,
} from 'domain/entities/Casting/Casting';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Formatter } from 'utils/formatter';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { SimpleToolTip } from 'ui/components/company/features/SimpleToolTip/SimpleToolTip';
import { CastingItemForm } from 'ui/components/company/patterns/CastingItemForm/CastingItemForm';
import { UserItem } from 'ui/components/company/patterns/UserItem/UserItem';
import { Button } from 'ui/components/company/elements/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCastingItem } from './useCastingItem';
dayjs.extend(isSameOrBefore);

const Container = styled.div`
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: ${(props: { needFirstContact: boolean }) =>
    props.needFirstContact ? '#ffe7e7' : 'var(--spr-white)'};
`;

const CastingDate = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px 5px 0;
`;

const StatusText = styled.div`
  padding: 4px 20px;
  margin: 0 10px 5px 0;
  border-radius: 4px;
  border: 1px solid var(--gray-dark-3);
  font-size: 9px;
`;

const DateStart = styled.div`
  font-size: 9px;
  padding-bottom: 2px;
  width: 150px;

  span {
    font-size: 10px;
  }
`;

const ProjectTitle = styled.div`
  font-size: 12px;

  span {
    font-size: 16px;
    display: inline;
    margin: 2px;
  }

  svg {
    vertical-align: middle;
  }
`;

const PassedDays = styled.div`
  ${(props: { isWarning: boolean }) =>
    props.isWarning && 'color: var(--spr-warn);font-weight: 700;'}

  span {
    font-size: 10px;
  }
`;

const CastingDetailsContainer = styled.div`
  display: flex;
  padding: 10px 0;
  gap: 20px;
`;

const MatchPoint = styled.div`
  width: 49%;
  background: var(--spr-back1);
  padding: 10px 10px;
  border-radius: 5px;
`;

const MatchPointTitle = styled.div`
  font-weight: 700;
  font-size: 10px;
`;

const MatchPointText = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.87);
`;

const SubTitle = styled.div`
  font-weight: 700;
  white-space: pre-wrap;
`;

const CastingLinks = styled.div`
  padding-top: 10px;
  gap: 10px;
  display: flex;
  justify-content: flex-end;

  button {
    line-height: 16px;
  }
`;

const WrapText = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
`;

export const CastingItem: React.FC<{
  casting: Casting;
}> = ({ casting }) => {
  if (!(casting.introduced_user && casting.spreader)) {
    return null;
  }
  const title = casting.project?.title ? Formatter.textCut(casting.project?.title, 30) : '';
  const navigate = useNavigate();
  const [needFirstContact, setNeedFirstContact] = useState(
    casting.status === CASTING_STATUS.WAITING_FIRST_CONTACT,
  );

  const { loading, getSurveyData, unArchiveCastChat } = useCastingItem();

  const goToChatRoom = () => {
    navigate(`/company/main/chat/${casting.chat_room_id}`);
  };
  useEffect(() => {
    setNeedFirstContact(casting.status === CASTING_STATUS.WAITING_FIRST_CONTACT);
  }, [casting.status]);

  return (
    <Container needFirstContact={needFirstContact}>
      <CastingDate>
        {casting.status && (
          <StatusText>{CASTING_STATUS_DISPLAY_TEXT_DICT[casting.status]}</StatusText>
        )}
        <br />
        <DateStart>
          <span>紹介成立日：</span>
          {casting.assigned_at && dayjs(casting.assigned_at).format('YYYY/MM/DD HH:mm')}
        </DateStart>
        {!!casting.passed_days && casting.passed_days >= 2 && (
          <SimpleToolTip tooltipText="紹介日から2週間以内を目処に面談日の確定と面談日の入力をお願いします。<br />期間が経過しますと面談者・スプレッダーからの評価が下がる場合があります。">
            <PassedDays isWarning={casting.passed_days >= 10}>
              <span>紹介日から</span>
              {casting.passed_days}日<span>経過</span>
            </PassedDays>
          </SimpleToolTip>
        )}
      </CastingDate>
      <ProjectTitle>
        <span>
          <AssignmentIcon width={16} height={16} />
        </span>
        {title}
      </ProjectTitle>
      <CastingItemForm casting={casting} />
      <CastingDetailsContainer>
        <UserItem user={casting.introduced_user} userType="casted" viewType="detail" realName />
        <UserItem user={casting.spreader} userType="spreader" viewType="detail" realName />
      </CastingDetailsContainer>
      <CastingDetailsContainer>
        <MatchPoint>
          <MatchPointTitle>紹介された方のマッチポイント</MatchPointTitle>
          {casting.match_points.map((matchPoint, index) => (
            <MatchPointText key={index}>
              <SubTitle>{matchPoint.question}</SubTitle>
              {matchPoint.answer ? <WrapText>{matchPoint.answer}</WrapText> : <div>記載なし</div>}
            </MatchPointText>
          ))}
          {casting.description && (
            <>
              <SubTitle>詳細内容</SubTitle>
              <WrapText>{casting.description}</WrapText>
            </>
          )}
        </MatchPoint>
        <MatchPoint>
          <MatchPointTitle>スプレッダーからの紹介文</MatchPointTitle>
          {casting.message_from_spreader && (
            <div dangerouslySetInnerHTML={{ __html: casting.message_from_spreader }} />
          )}
        </MatchPoint>
      </CastingDetailsContainer>
      {!needFirstContact && (
        <CastingLinks>
          {casting.chat_room_id && casting.is_chat_archived && (
            <Button
              button_type="blue"
              fontSize="10px"
              padding="5px"
              onClick={() => unArchiveCastChat(casting.id)}
            >
              チャットのアーカイブを戻す
            </Button>
          )}
          {casting.chat_room_id && (
            <Button button_type="blue" fontSize="10px" padding="5px" onClick={goToChatRoom}>
              メッセージ確認・送信
            </Button>
          )}
          {casting.meetup_date_at &&
            dayjs(casting.meetup_date_at).isSameOrBefore(dayjs().endOf('day')) &&
            !casting.survey_company_user_answer_at && (
              <Button
                button_type="red"
                fontSize="10px"
                padding="5px"
                disabled={loading}
                onClick={() => getSurveyData(casting.id)}
              >
                面談満足度アンケート回答
              </Button>
            )}
        </CastingLinks>
      )}
    </Container>
  );
};
