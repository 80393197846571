import styled from '@emotion/styled';
import {
  PROJECT_CATEGORY_DESCRIPTIONS,
  PROJECT_CATEGORY_NAMES,
  SPREADY_CHECK,
} from 'domain/entities/Project/Project';
import { IProjectForm } from 'domain/entities/factories/Project';
import { Controller } from 'react-hook-form';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { FormCheckbox } from 'ui/components/company/elements/FormCheckbox/FormCheckbox';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { FormGuide } from 'ui/components/company/elements/FormGuide/FormGuide';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { FormMultiSelect } from 'ui/components/company/elements/FormMultiSelect/FormMultiSelect';
import { FormRadio } from 'ui/components/company/elements/FormRadio/FormRadio';
import { FormSectionTitle } from 'ui/components/company/elements/FormSectionTitle/FormSectionTitle';
import { FormTextArea } from 'ui/components/company/elements/FormTextArea/FormTextArea';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { Prompt } from 'ui/components/company/elements/Prompt/Prompt';
import { SectionTitle } from 'ui/components/company/elements/SectionTitle/SectionTitle';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { ImageUpload } from 'ui/components/company/features/ImageUpload/ImageUpload';
import { MultiInput } from 'ui/components/company/features/MultipleInput/MultipleInput';
import { PreviewApplication } from 'ui/components/company/patterns/PreviewApplication/PreviewApplication';
import { PreviewProject } from 'ui/components/company/patterns/PreviewProject/PreviewProject';
import { UsageDescription } from 'ui/components/company/screens/modals/UsageDescription/UsageDescription';
import HelpIcon from 'ui/images/company/icons/help.svg';
import pencil from 'ui/images/form/pencil.png';
import { mqUser } from 'utils/responsive';
import { ProjectTitleForm } from './ProjectTitle';
import { PublishSetting } from './PublishSetting';
import { useSessionManageForm } from './useSessionManageForm';
import { useUsageDescriptionModal } from './useUsageDescriptionModal';
import { DeleteDraftProjectConfirm } from 'ui/components/company/screens/modals/DeleteDraftProjectConfirm/DeleteDraftProjectConfirm';

const PageWrapper = styled.div`
  padding: 30px 30px 30px 0;
`;

const Titles = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 40px;
`;

const FormWrapper = styled.div`
  width: calc(100vw - var(--width-pc-sidebar) - 30px);
  display: grid;
  grid-template-columns: 5fr minmax(360px, 3fr);
  gap: 40px;
  ${mqUser('NARROW')} {
    gap: 20px;
  }
`;

const CategoryName = styled.div`
  margin-top: 4px;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
`;

const CategoryDescription = styled.div`
  margin-bottom: 4px;
  font-size: var(--font-medium);
`;

const NumberOfPeople = styled.div`
  display: grid;
  grid-template-columns: 280px auto;
  place-items: center normal;
  gap: 8px;
`;

const SmallText = styled.p`
  margin: 10px 0 0;
  font-size: 11px;
  line-height: 1.4em;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray);
  margin-block: 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 40px;
`;

/**
 * セッション 作成・編集 フォーム
 */
export const SessionManageForm: React.FC = () => {
  const {
    categoryOptions,
    privacyOptions,
    companyUserOptions,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors },
    showPrompt,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    arrayFields,
    appendField,
    removeEmptyFields,
    project,
    projectForPreview,
    fileName,
    isNewProject,
    draftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    setShowDeleteConfirm,
    deleteDraftProject,
  } = useSessionManageForm();
  const { openModal, closeModal, createModal } = useUsageDescriptionModal();

  return (
    <PageWrapper>
      {createModal(<UsageDescription onClick={closeModal} />)}

      <Prompt
        when={showPrompt}
        message={`このページを離れてもよろしいですか？\n行った変更が保存されない可能性があります。`}
      />

      <SnackBar
        open={snackbarMessage !== ''}
        setOpen={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />

      <Titles>
        <PageTitle title={`募集案件の${isNewProject ? '作成' : '編集'}`} size="large" />
        <IconButton onClick={openModal} icon={HelpIcon} buttonStyle={{ padding: '8px 16px' }}>
          ご利用・募集案件作成について
        </IconButton>
      </Titles>

      {Object.keys(errors).length > 0 && (
        <FormError
          wide
          message="入力エラーがあります。入力内容をご確認ください。"
          style={{ marginBottom: '40px' }}
        />
      )}
      {!isNewProject && !draftId && (
        <>
          <SectionTitle title="募集の公開設定" icon={pencil} />
          <PublishSetting
            project={project}
            close={close}
            makePending={makePending}
            reopen={reopen}
          />
        </>
      )}
      <FormWrapper>
        <div>
          <SectionTitle title="募集内容の入力" icon={pencil} style={{ marginBottom: '20px' }} />

          <FormSectionTitle title="募集案件タイトル" isRequired />
          <ProjectTitleForm
            setValue={setValue}
            getValues={getValues}
            control={control}
            errors={errors}
            trigger={trigger}
            watch={watch}
          />

          <Separator />

          <FormSectionTitle title="メイン画像" isRequired />
          <p>画像ファイルをアップロードしてください。</p>
          {errors.main_pic ? (
            <FormError message="画像が未登録です" style={{ marginBottom: '8px' }} />
          ) : null}
          <ImageUpload
            imagePath={watch('main_pic')}
            directory="project"
            fileName={fileName}
            fileCategory="company"
            disabled={requesting}
            withPreview
            width={960}
            height={540}
            aspectRatio={16 / 9}
            placeholder="image"
            onComplete={onImageUpload}
            isSquare
            isValid={watch('main_pic').length > 0 && !errors.main_pic}
          />
          <SmallText>
            ※ 推奨サイズ：横960px以上、縦540px以上。16:9の比率にリサイズされます
            <br />※ 16:9ではない場合、余白を追加します
          </SmallText>

          <Separator />

          <FormSectionTitle title="会いたい方へ、メッセージ" />
          <FormGuide
            title="記述ポイント"
            description={`セッションページでまず一番最初にユーザーが目にする欄です。どんな相手にどんな話をなぜしたいと思っているのか、想いを伝えましょう。\n想いに共感したユーザーが応募してくれることを狙います。`}
            style={{ margin: '12px 0' }}
          />
          {errors.summary ? (
            <FormError
              message="メッセージは150字以内で入力してください"
              style={{ marginBottom: '8px' }}
            />
          ) : null}
          <Controller
            name="summary"
            control={control}
            render={({ field }) => (
              <FormTextArea
                {...field}
                rows={4}
                max={150}
                placeholder="例：日々のマネジメント業務に追われ、生産性向上や工数削減が出来ずに悩まれている方が多いかと思います。営業の「無駄」を取り除き、生産性の高い業務環境を構築するために様々なニーズやご意見を頂きたいと考えております。"
                hasError={!!errors.summary?.message}
              />
            )}
          />

          <Separator />

          <FormSectionTitle title="対象者" isRequired />
          <FormGuide
            title="記述ポイント"
            description={`・話を聞きたい対象者についての条件を記載します。
                          ・Spreadyは自己応募だけでなく、ユーザーが紹介してくれる機能もあるため、より具体的に記載して周りにいる方が当てはまるかどうか想起できるよう記載しましょう
                          ・対象者例
                            ・○○のような課題を1年以上抱えている
                            ・○○業界で、○○について設計開発経験がある\n想いに共感したユーザーが応募してくれることを狙います。`}
            style={{ margin: '12px 0' }}
          />
          {errors.targets ? (
            <FormError message="対象者が未入力です" style={{ marginBottom: '8px' }} />
          ) : null}
          <MultiInput<IProjectForm>
            label="対象者"
            fields={arrayFields['targets'].fields}
            control={control}
            errors={errors}
            keyName="targets"
            placeholder="対象者を入力"
            appendText="対象者を追加"
            append={() => appendField('targets')}
            remove={() => removeEmptyFields('targets')}
          />

          <Separator />

          <FormSectionTitle title="お話したい内容" isRequired />
          <FormGuide
            title="記述ポイント"
            description={`・インタビュー当日お話したい内容について具体的に記載します。（3-5点推奨）
                          ・応募者が共感する、インタビューを受けることを魅力的に思う内容を記述してください。
                          ・インタビューで何を求めているか明確に記載してください（「相談をしたい」「フィードバックを聞きたい」「ディスカッションしたい」等）
                          ・専門用語や略語はなるべくお控えいただき、使用する場合は注釈をつけていただくと、応募が集まりやすくなります`}
            style={{ margin: '12px 0' }}
          />
          {errors.themes ? (
            <FormError message="お話したい内容が未入力です" style={{ marginBottom: '8px' }} />
          ) : null}
          <MultiInput<IProjectForm>
            fields={arrayFields['themes'].fields}
            errors={errors}
            control={control}
            keyName="themes"
            placeholder="お話したい内容を入力"
            appendText="話したい内容を追加"
            append={() => appendField('themes')}
            remove={() => removeEmptyFields('themes')}
          />

          <Separator />

          <FormSectionTitle title="ご相談の背景" isRequired />
          <FormGuide
            title="記述ポイント"
            description={`・なぜ対象者とお話がしたいのか、自分たちの抱えている課題やフェーズ、自分たちの成し遂げたいことについて記載します。
                          ・何に困っていて、どんな情報(インサイト)を得たいのかが明確になっている。応募者が共感する、手を差し伸べたくなる、応援したくなる内容を記述してください
                          ・タイトル例orテンプレ
                          私たちは新規事業として○○している方向けの企画を検討しております。○○を通じて、○○の課題感はある程度掴めてきましたが、一方で○○については推定の域を出ておりません。そこで、○○している方に対して、○○についてお伺いし、○○の解像度を上げていきたいと考えております。`}
            style={{ margin: '12px 0' }}
          />
          {errors.background ? (
            <FormError message="ご相談の背景が未入力です" style={{ marginBottom: '8px' }} />
          ) : null}
          <Controller
            name="background"
            control={control}
            render={({ field }) => (
              <FormTextArea
                {...field}
                rows={4}
                placeholder="ご相談の背景を入力"
                hasError={!!errors.background?.message}
              />
            )}
          />

          <Separator />

          <FormSectionTitle title="ゴールイメージ" />
          <FormGuide
            title="記述ポイント"
            description={`・面談を通じて何を成し遂げたいのかについて記載します。
                          ・この事業を展開することで、何を実現したいのかを明確にする。応募者が共感する、手を差し伸べたくなる、応援したくなる内容を記述してください
                          ・タイトル例orテンプレ
                          お伺いしたお話をもとに、○○についてのサービスを新しく企画する予定です。`}
            style={{ margin: '12px 0' }}
          />
          {errors.goal ? (
            <FormError
              message="ゴールイメージは500文字以内で入力してください"
              style={{ marginBottom: '8px' }}
            />
          ) : null}
          <Controller
            name="goal"
            control={control}
            render={({ field }) => (
              <FormTextArea
                {...field}
                rows={4}
                max={500}
                placeholder="ゴールイメージを入力"
                hasError={!!errors.goal?.message}
              />
            )}
          />

          <Separator />

          <FormSectionTitle title="締めくくりのメッセージ" />
          <FormGuide
            title="記述ポイント"
            description="最後にお伝えしたいことがあればメッセージを記載します。「ゴールイメージ」の下に配置されます。"
            style={{ margin: '12px 0' }}
          />
          {errors.outro ? (
            <FormError
              message="締めくくりのメッセージは500文字以内で入力してください"
              style={{ marginBottom: '8px' }}
            />
          ) : null}
          <Controller
            name="outro"
            control={control}
            render={({ field }) => (
              <FormTextArea
                {...field}
                rows={4}
                max={500}
                placeholder="締めくくりのメッセージを入力"
                hasError={!!errors.outro?.message}
              />
            )}
          />
        </div>

        <PreviewProject project={projectForPreview} />

        <div>
          <SectionTitle
            title="応募時入力項目の設定"
            icon={pencil}
            style={{ margin: '40px 0 20px' }}
          />
          <p>
            応募者とメッセージを開始して面談をするかどうか判断するために必要な情報を応募者に応募時に記載いただく項目です。
          </p>

          <FormSectionTitle title="マッチポイント" isRequired />
          <FormGuide
            title="記述ポイント"
            description={`・マッチポイントは応募者が該当する項目にチェックをいれ、詳細を記入いただく項目です。
                          ・対象者で入力した内容が反映されていますが、編集が可能です。
                          ・メッセージを開始するか判断するために必要な必須要件を記載ください。`}
            style={{ margin: '12px 0' }}
          />
          {errors.match_points ? (
            <FormError message="マッチポイントが未入力です" style={{ marginBottom: '8px' }} />
          ) : null}
          <MultiInput<IProjectForm>
            fields={arrayFields['match_points'].fields}
            control={control}
            errors={errors}
            keyName="match_points"
            placeholder="例：営業メンバーを5-10名程度抱えている、営業メンバーを11名以上抱えている"
            appendText="マッチポイントを追加"
            append={() => appendField('match_points')}
            remove={() => removeEmptyFields('match_points')}
          />

          <Separator />

          <FormSectionTitle title="詳細内容" isRequired />
          <FormGuide
            title="記述ポイント"
            description={`・マッチポイント以外で応募者に応募時に聞きたい項目をフリーで聞くことが可能です。
                          ・メッセージを開始するか判断するに必要な情報を記載ください。（3点まで推奨）`}
            style={{ margin: '12px 0' }}
          />
          {errors.match_point_text?.type === 'required' ? (
            <FormError message="詳細内容が未入力です" style={{ marginBottom: '8px' }} />
          ) : null}
          {errors.match_point_text?.type === 'max' ? (
            <FormError
              message="詳細内容は500文字以内で入力してください"
              style={{ marginBottom: '8px' }}
            />
          ) : null}
          <Controller
            name="match_point_text"
            control={control}
            render={({ field }) => (
              <FormTextArea
                {...field}
                rows={4}
                max={500}
                placeholder="例：差し支えなければ、企業名を営業組織で抱えている課題やお悩みについてお聞かせください"
                hasError={!!errors.match_point_text?.message}
              />
            )}
          />

          <SectionTitle title="募集の詳細設定" icon={pencil} style={{ margin: '40px 0 20px' }} />

          <FormSectionTitle title="社名非公開オプション" isRequired />
          <p>
            社名を非公開で募集をすることが出来ます。社名を非公開を選択されますと、チケット消化タイミングでチケット代に加えて50,000円（税別）が請求されます。
          </p>
          <Controller
            name="privacy"
            control={control}
            render={({ field }) => (
              <FormRadio
                id="privacy"
                name="privacy"
                selected={field.value}
                disabled={!isNewProject && !draftId}
                options={privacyOptions}
                onChange={(e) => field.onChange(Number(e.target.value))}
              />
            )}
          />
          <p>※案件を公開後にこのオプションを変更することはできません</p>

          <Separator />

          <FormSectionTitle title="セッションの担当者" isRequired style={{ marginBottom: '8px' }} />
          {errors.management_user_ids ? (
            <FormError message="セッションの担当者が未選択です" style={{ marginBottom: '8px' }} />
          ) : null}
          <Controller
            name="management_user_ids"
            control={control}
            render={({ field }) => (
              <FormMultiSelect
                name="management_users"
                options={companyUserOptions}
                selected={field.value}
                enableAutoComplete
                placeholder="担当者を選択"
                hasError={!!errors.management_user_ids}
                onChange={field.onChange}
              />
            )}
          />

          <Separator />

          <FormSectionTitle
            title="セッションのカテゴリー"
            isRequired
            style={{ marginBottom: '8px' }}
          />
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <FormRadio
                id="category"
                name="category"
                selected={field.value}
                options={categoryOptions}
                onChange={(e) => field.onChange(e.target.value)}
                labelRenderer={(option) => (
                  <>
                    {/* @ts-expect-error 古いカテゴリが残っているため */}
                    <CategoryName>{PROJECT_CATEGORY_NAMES[option.value]}</CategoryName>
                    <CategoryDescription>
                      {/* @ts-expect-error 古いカテゴリが残っているため */}
                      {PROJECT_CATEGORY_DESCRIPTIONS[option.value]}
                    </CategoryDescription>
                  </>
                )}
              />
            )}
          />

          <Separator />

          <FormSectionTitle title="会いたい人数" isRequired />
          <p>
            会いたい人数の目安を入力お願いします。本項目は募集画面には表示されず、Spreadyの管理のために利用されます。
          </p>
          {errors.number_of_people?.type === 'required' ||
          errors.number_of_people?.type === 'typeError' ? (
            <FormError message="会いたい人数が未入力です" style={{ marginBottom: '8px' }} />
          ) : null}
          {errors.number_of_people?.type === 'range' ? (
            <FormError
              message="1以上99以下の数値を入力してください"
              style={{ marginBottom: '8px' }}
            />
          ) : null}
          <Controller
            name="number_of_people"
            control={control}
            render={({ field }) => (
              <NumberOfPeople>
                <FormInput
                  {...field}
                  type="number"
                  min={1}
                  max={99}
                  placeholder="人数を選択"
                  hasError={!!errors.number_of_people}
                />
                <span>人</span>
              </NumberOfPeople>
            )}
          />

          <Separator />

          <FormSectionTitle title="メッセージテンプレート" />
          <FormGuide
            title="記述ポイント"
            description={`応募者に送る最初のメッセージのテンプレートを設定しておくことができます。

                          例：お世話になっております。○○株式会社の○○です。この度は弊社の案件にご応募いただき、ありがとうございます。ぜひお話しをお聞きしたいと考えておりまして、一度オンラインにて面談のお時間をいただけますでしょうか？以下の日程からご都合の良い日時をお選びくださいませ。`}
            style={{ margin: '12px 0' }}
          />
          {errors.chat_template_text?.type === 'max' ? (
            <FormError
              message="メッセージテンプレートは500文字以内で入力してください"
              style={{ marginBottom: '8px' }}
            />
          ) : null}
          <Controller
            name="chat_template_text"
            control={control}
            render={({ field }) => (
              <FormTextArea
                {...field}
                rows={4}
                max={500}
                placeholder="メッセージテンプレートを入力"
                hasError={!!errors.chat_template_text?.message}
              />
            )}
          />

          <Separator />

          <FormSectionTitle title="面談場所" style={{ marginBottom: '8px' }} />
          <Controller
            name="enable_offline_interview"
            control={control}
            render={({ field }) => (
              <FormCheckbox
                id="enable_offline_interview"
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
              >
                オンライン面談可
              </FormCheckbox>
            )}
          />

          <Separator />

          <FormSectionTitle title="このセッションのシェアの可否" style={{ marginBottom: '8px' }} />
          <p>シェアを不可にすると、ログイン後のユーザにのみセッションが表示されます。</p>
          <Controller
            name="share_enabled"
            control={control}
            render={({ field }) => (
              <FormCheckbox
                id="share_enabled"
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
              >
                シェア可
              </FormCheckbox>
            )}
          />
        </div>

        <PreviewApplication project={projectForPreview} />
      </FormWrapper>

      <Buttons>
        {project.spready_check === SPREADY_CHECK.OK && !draftId ? (
          <ActionButton
            bgColor="secondary-dark"
            style={{ width: '400px' }}
            onClick={onSubmit(true)}
          >
            募集内容を更新する
          </ActionButton>
        ) : (
          <>
            {draftId && (
              <ActionButton
                fontColor="warn"
                bgColor="warn-paler"
                style={{ width: '400px' }}
                onClick={() => setShowDeleteConfirm(true)}
              >
                下書き削除
              </ActionButton>
            )}
            <ActionButton bgColor="white" style={{ width: '400px' }} onClick={onSubmit()}>
              下書き保存
            </ActionButton>
            <ActionButton
              bgColor="secondary-dark"
              style={{ width: '400px' }}
              onClick={onSubmit(true)}
            >
              募集を開始
            </ActionButton>
          </>
        )}
      </Buttons>
      {deleteConfirmModal.createModal(
        <DeleteDraftProjectConfirm
          closeModal={() => setShowDeleteConfirm(false)}
          onExecute={() => deleteDraftProject()}
        />,
        { onClose: () => setShowDeleteConfirm(false) },
      )}
    </PageWrapper>
  );
};
