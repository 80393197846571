import { SessionManageForm } from 'ui/components/company/patterns/SessionManageForm/SessionManageForm';

/**
 * セッション作成ページ
 */
const SessionCreate = () => {
  return <SessionManageForm />;
};

export default SessionCreate;
