import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserLogout } from 'application/usecases/CompanyUserLogout';
import { companyAppContext } from 'application/contexts/useCompanyApp';

export const useHeader = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserLogout = diContainer.resolve(CompanyUserLogout);
  const { setIsSideOpen } = useContext(companyAppContext);
  const { user, resetUser } = useContext(companyUserContext);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleLogout = async () => {
    await companyUserLogout.execute();
    resetUser();
  };

  const menuItems = [
    { text: 'ダッシュボード', url: '/company/main/' },
    { text: 'セッション一覧', url: '/company/main/projects/' },
    { text: '紹介一覧', url: '/company/main/castings/' },
    { text: 'メッセージ', url: '/company/main/chat/rooms/' },
    { text: 'スプレッダーに会いたいリクエスト', url: '/company/main/meet/' },
    { text: '企業ユーザー一覧・追加・編集', url: '/company/main/users/' },
    { text: '企業情報確認・編集', url: '/company/main/edit/' },
    { text: 'ログアウト', url: '/company/', handler: handleLogout },
  ];

  const onItemClick = (item: (typeof menuItems)[0]) => {
    setPopoverOpen(false);
    item.handler?.();
    navigate(item.url);
  };

  return {
    user,
    popoverOpen,
    setPopoverOpen,
    menuItems,
    onItemClick,
    setIsSideOpen,
  };
};
