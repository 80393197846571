import { inject, injectable } from 'tsyringe';
import { IUserRepository } from 'application/repositorySchemas/IUserRepository';
import { type ISessionStorageUsable } from 'interfaceAdapter/gatewaySchemas/ISessionStorageUsable';
import { SessionStorageUsable } from 'infrastructure/storages/SessionStorageUsable';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IRegisterClient } from 'interfaceAdapter/gatewaySchemas/IRegisterClient';
import { type ILogoutClient } from 'interfaceAdapter/gatewaySchemas/ILogoutClient';
import { type IConfirmEmailClient } from 'interfaceAdapter/gatewaySchemas/IConfirmEmailClient';
import { type ISendEmailChangeEmailClient } from 'interfaceAdapter/gatewaySchemas/ISendEmailChangeEmailClient';
import { type IResendEmailChangeConfirmClient } from 'interfaceAdapter/gatewaySchemas/IResendEmailChangeConfirmClient';
import { type IVerifyEmailChangeCodeClient } from 'interfaceAdapter/gatewaySchemas/IVerifyEmailChangeCodeClient';
import { type ISendPasswordResetEmailClient } from 'interfaceAdapter/gatewaySchemas/ISendPasswordResetEmailClient';
import { type IResetPasswordClient } from 'interfaceAdapter/gatewaySchemas/IResetPasswordClient';
import { type IGenerateFacebookAuthTokenClient } from 'interfaceAdapter/gatewaySchemas/IGenerateFacebookAuthTokenClient';
import { type IGenerateFacebookAuthUrlClient } from 'interfaceAdapter/gatewaySchemas/IGenerateFacebookAuthUrlClient';
import { type IUpdateFacebookIdClient } from 'interfaceAdapter/gatewaySchemas/IUpdateFacebookIdClient';
import { type IWithdrawalClient } from 'interfaceAdapter/gatewaySchemas/IWithdrawalClient';
import { RegisterUserParamsSchema, type IUserFactory } from 'domain/entities/factories/User';
import { User } from 'domain/entities/User/User';
import { FacebookAuthType } from 'application/contexts/useAuth';
import {
  buildResponse,
  HttpBadRequest,
  HttpNotFound,
  HttpUnauthorized,
} from 'domain/types/httpResponse';

@injectable()
export class UserRepository implements IUserRepository {
  private readonly storage: ISessionStorageUsable;

  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IRegisterClient')
    public readonly registerClient: IRegisterClient,
    @inject('ILogoutClient')
    public readonly logoutClient: ILogoutClient,
    @inject('IConfirmEmailClient')
    public readonly confirmEmailClient: IConfirmEmailClient,
    @inject('ISendEmailChangeEmailClient')
    public readonly sendEmailChangeEmailClient: ISendEmailChangeEmailClient,
    @inject('IResendEmailChangeConfirmClient')
    public readonly resendEmailChangeConfirmClient: IResendEmailChangeConfirmClient,
    @inject('IVerifyEmailChangeCodeClient')
    public readonly verifyEmailChangeCodeClient: IVerifyEmailChangeCodeClient,
    @inject('ISendPasswordResetEmailClient')
    public readonly sendPasswordResetEmailClient: ISendPasswordResetEmailClient,
    @inject('IResetPasswordClient')
    public readonly resetPasswordClient: IResetPasswordClient,
    @inject('IGenerateFacebookAuthTokenClient')
    public readonly generateFacebookAuthTokenClient: IGenerateFacebookAuthTokenClient,
    @inject('IGenerateFacebookAuthUrlClient')
    public readonly generateFacebookAuthUrlClient: IGenerateFacebookAuthUrlClient,
    @inject('IUpdateFacebookIdClient')
    public readonly updateFacebookIdClient: IUpdateFacebookIdClient,
    @inject('IWithdrawalClient')
    public readonly withdrawalClient: IWithdrawalClient,
    @inject('IUserFactory')
    public readonly factory: IUserFactory,
  ) {
    this.storage = new SessionStorageUsable();
  }

  public flushAllSessionStorage(): void {
    this.storage.flushAll();
  }

  public register = async (params: RegisterUserParamsSchema): Promise<void> => {
    const { message } = await this.registerClient.post({
      params: {
        ...params,

        // 以下のパラメータを追加しないと、APIがバリデーションエラーを返す
        company_name: null,
        company_job: null,
        profiee_url: null,
        facebook_url: null,
        is_profiee_url_open: true,
        is_facebook_url_open: true,
        is_company_info_open: true,
      },
    });
    if (message) {
      throw new Error(message);
    }
  };

  public logout = async (): Promise<void> => {
    const token = this.userToken.get() ?? '';
    try {
      await this.logoutClient.post({ token });
    } catch (error) {
      throw new Error('予期せぬエラー');
    }
  };

  public confirmEmail = async (
    code: string,
  ): Promise<{ user: User; isInvited: boolean; isIntroduced: boolean }> => {
    const { data, message, status } = await this.confirmEmailClient.post({ code });

    if (message) {
      const throwable = buildResponse(status, message);
      if (
        throwable instanceof HttpNotFound ||
        throwable instanceof HttpUnauthorized ||
        throwable instanceof HttpBadRequest
      ) {
        throw throwable;
      }
    }

    if (!data || !data.user) {
      throw new Error('予期せぬエラー');
    }
    return {
      user: this.factory.buildUser({
        ...data.user,

        // 以下、UserEntityが期待する値を埋める
        tutorial_finished: false,
        is_company_info_open: false,
        has_new_stamps: false,
        has_new_feedback: false,
      }),
      isInvited: data.is_asked_casting_user || false,
      isIntroduced: data.is_introduced_user || false,
    };
  };

  // TODO: UXリニューアル後、この処理は不要になる為削除する
  public sendEmailChangeEmail = async (id: number, email: string): Promise<void> => {
    const token = this.userToken.get() ?? '';
    const { message } = await this.sendEmailChangeEmailClient.post({ token, id, email });
    if (message) {
      throw new Error(message);
    }
  };

  public resendEmailChangeConfirm = async (userId: number, newEmail: string): Promise<void> => {
    const token = this.userToken.get() ?? '';
    const { message } = await this.resendEmailChangeConfirmClient.post({ token, userId, newEmail });
    if (message) {
      throw new Error(message);
    }
  };

  public verifyEmailChangeCode = async (code: string): Promise<void> => {
    const token = this.userToken.get() ?? '';
    const { data } = await this.verifyEmailChangeCodeClient.post({ token, code });
    if (data !== true) {
      throw new Error('予期せぬエラー');
    }
  };

  public sendPasswordResetEmail = async (email: string): Promise<void> => {
    const token = this.userToken.get() ?? '';
    const { message } = await this.sendPasswordResetEmailClient.post({ token, email });
    if (message) {
      throw new Error(message);
    }
  };

  public resetPassword = async (code: string, password: string): Promise<void> => {
    const token = this.userToken.get() ?? '';
    const { message } = await this.resetPasswordClient.post({ token, code, password });
    if (message) {
      throw new Error(message);
    }
  };

  public generateFacebookAuthToken = async (url: string): Promise<string> => {
    const { data, message } = await this.generateFacebookAuthTokenClient.post({ url });
    if (message) {
      throw new Error(message);
    }
    return data.access_token;
  };

  public generateFacebookAuthUrl = async (authType: FacebookAuthType): Promise<string> => {
    const { data, message } = await this.generateFacebookAuthUrlClient.post({ authType });
    if (message) {
      throw new Error(message);
    }
    if (!data || !data.url) {
      throw new Error('URL生成に失敗しました');
    }
    return data.url;
  };

  public updateFacebookId = async (accessToken: string): Promise<void> => {
    const token = this.userToken.get() ?? '';
    const { message } = await this.updateFacebookIdClient.post({ token, accessToken });
    if (message) {
      throw new Error(message);
    }
  };

  public withdraw = async (): Promise<void> => {
    const token = this.userToken.get() ?? '';
    const { message } = await this.withdrawalClient.post({ token });
    if (message) {
      throw new Error(message);
    }
  };
}
