import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useCompanyProjectCheckoutSuccess } from './useCompanyProjectCheckoutSuccess';

const Wrapper = styled.div`
  padding: 40px;
  min-width: 550px;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: var(--font-xxx-large);
  line-height: 1.2;
  text-align: center;
`;

const Text = styled.p<{ margin?: string; align?: string; fontWeight?: string }>`
  margin: ${(props) => props.margin || '0'};
  text-align: ${(props) => props.align};
  font-weight: ${(props) => props.fontWeight ?? 'normal'};
  font-size: var(--font-medium);
  line-height: 1.5;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 24px;
`;

const Button = styled.button<{ padding?: string; bgColor: string; textColor?: string }>`
  border-radius: 4px;
  padding: ${(props) => props.padding || '12px 30px'};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  border: none;
  white-space: nowrap;
  cursor: pointer;
`;

/**
 * Stripeの成功時のリダイレクト先ページ
 */
const CompanyProjectCheckoutSuccess = () => {
  const { completed, message } = useCompanyProjectCheckoutSuccess();

  if (completed) {
    return (
      <Wrapper>
        <Title>決済とメッセージ送信完了</Title>
        <Text margin="30px 40px 40px" align="center">
          大変お待たせしました。 <br />
          決済を頂きありがとうございます。 <br />
          メッセージの送信も完了しました。
          <br />
          応募なさったユーザー様と面談日のお約束をお願いいたします。
        </Text>
        <Buttons>
          <Link to="/company/main/castings">
            <Button bgColor="var(--gray-light-2)" onClick={() => {}}>
              紹介一覧へ
            </Button>
          </Link>
        </Buttons>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Text align="center">{message}</Text>
    </Wrapper>
  );
};

export default CompanyProjectCheckoutSuccess;
