import styled from '@emotion/styled';
import { useRef, useState } from 'react';
import { useSimpleToolTip } from './useSimpleToolTip';

type Props = {
  tooltipText: string;
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  width?: string;
  fontSize?: string;
  offset?: number;
};

type ToolTipProps = {
  width?: string;
  fontSize?: string;
};

const ToolTip = styled.div<ToolTipProps>`
  z-index: var(--tooltip);
  width: ${(props: { width?: string }) => props.width || 'auto'};
  background-color: rgba(0, 0, 0, 0.55);
  color: white;
  transition: all 0.15s ease-in-out;
  border-radius: 4px;
  font-size: ${(props: { fontSize?: string }) => props.fontSize || '12px'};
  padding: 8px;
  white-space: pre;
  position: fixed;
  filter: drop-shadow(0 0 20px rgb(0 0 0 / 15%));
  border-radius: 8px;
  text-align: left;
`;

/**
 * hover時に表示するツールチップ
 */
export const SimpleToolTip: React.FC<Props> = ({
  tooltipText,
  position = 'bottom',
  children,
  width,
  fontSize,
  offset = 8,
}) => {
  const basisRef = useRef<HTMLDivElement>(null);
  const toolTipRef = useRef<HTMLDivElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const { tooltipStyle } = useSimpleToolTip({
    basisRef,
    toolTipRef,
    position,
    offset,
  });

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={basisRef}>
        {children}
      </div>
      <ToolTip
        ref={toolTipRef}
        style={{
          opacity: isTooltipVisible ? 1 : 0,
          visibility: isTooltipVisible ? 'visible' : 'hidden',
          ...tooltipStyle,
        }}
        width={width}
        fontSize={fontSize}
        dangerouslySetInnerHTML={{ __html: tooltipText }}
      />
    </>
  );
};
