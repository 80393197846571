import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { HttpGone, HttpBadRequest } from 'domain/types/httpResponse';

const schema = yup
  .object({
    password: yup
      .string()
      .required()
      .min(9)
      .max(30)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.@])[a-zA-Z0-9\-_.@]*$/,
        '英字の大文字、英字の小文字、記号 -_.@ 、数字を全て含めて設定してください',
      ),
  })
  .required();

type CompanyUserSignUpForm = yup.InferType<typeof schema>;

export const useCompanyUserSignUp = () => {
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const navigate = useNavigate();
  const params = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CompanyUserSignUpForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [urlId, setUrlId] = useState('');
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [email, setEmail] = useState('');
  const [expired, setExpired] = useState(false);

  const onSubmit = handleSubmit(async ({ password }) => {
    if (urlId === '') {
      return;
    }
    setLoading(true);
    try {
      await companyUserRepository.registerPassword(urlId, password);
      setComplete(true);
      setTimeout(() => navigate('/company/register/user/profile/'), 3000);
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message);
      }
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (!params.url_id) {
      return;
    }
    setUrlId(params.url_id);

    const fetch = async () => {
      try {
        const { data } = await companyUserRepository.fetchPreCompanyUserByUrlId(
          params.url_id ?? '',
        );
        setEmail(data.email);
      } catch (e) {
        if (e instanceof HttpGone) {
          setExpired(true);
          return;
        }
        if (e instanceof Error || e instanceof HttpBadRequest) {
          throw new Error(e.message);
        }
      }
    };

    fetch();
  }, [params.url_id]);

  return {
    complete,
    expired,
    loading,
    control,
    errors,
    email,
    watch,
    urlId,
    onSubmit,
  };
};
