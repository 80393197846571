import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import { SectionTitle } from 'ui/components/company/elements/SectionTitle/SectionTitle';
import { FormRadio } from 'ui/components/company/elements/FormRadio/FormRadio';
import { FormTextArea } from 'ui/components/company/elements/FormTextArea/FormTextArea';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { Prompt } from 'ui/components/company/elements/Prompt/Prompt';
import { DisplayProgress } from 'ui/components/company/screens/modals/DisplayProgress/DisplayProgress';
import { DraftProjectComplete } from 'ui/components/company/screens/modals/DraftProjectComplete/DraftProjectComplete';
import EditIcon from 'ui/images/company/icons/edit.svg';
import { useProjectDraftMaker } from './useProjectDraftMaker';

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 0 120px;
`;

const Desc1 = styled.div`
  margin-bottom: 20px;
  font-size: var(--font-xx-large);
  font-weight: var(--font-bold);
`;

const Number = styled.div<{ fontSize: string }>`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: var(--primary-dark);
  font-size: ${({ fontSize }) => `var(--font-${fontSize})`};
  color: var(--white);
  text-align: center;
  line-height: 32px;
`;

const Desc2 = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  padding: 20px;
  border-radius: 12px;
  background-color: var(--primary-paler);
  font-size: var(--font-large);
  font-weight: var(--font-bold);
`;

const FormTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 20px 0 12px;
  font-size: var(--font-x-large);
  font-weight: var(--font-bold);
`;

/**
 * 案件募集の内容を自動生成するためのフォーム
 */
export const ProjectDraftMaker: React.FC = () => {
  const navigate = useNavigate();
  const {
    ideaObjectiveOptions,
    formState,
    control,
    progress,
    completed,
    onSubmit,
    onClose,
    progressModal,
    completeModal,
  } = useProjectDraftMaker();
  return (
    <Wrapper>
      <Prompt
        when={formState.isDirty && !formState.isSubmitted}
        message={`このページを離れてもよろしいですか？\n行った変更が保存されない可能性があります。`}
      />

      <Desc1>以下のかんたんな質問に答えると、自動で募集案件の下書きを作ります。</Desc1>

      <IconButton icon={EditIcon} onClick={() => navigate('/company/main/projects/create/')}>
        下書きを作らずに案件作成をする
      </IconButton>

      <SectionTitle
        title="１. 事業案の現状で「最も当てはまるもの」を選んでください。"
        style={{ margin: '40px 0 20px' }}
      />
      <Controller
        name="object"
        control={control}
        render={({ field }) => (
          <FormRadio
            options={ideaObjectiveOptions}
            selected={field.value}
            onChange={(e) => field.onChange(e.target.value)}
          />
        )}
      />

      <SectionTitle
        title="２. 以下の①②③④の箇所を入力してください。"
        complement={`\t※ 現時点で以下が定まっていない場合は、空白のままで構いません。`}
        style={{ margin: '40px 0 20px' }}
      />
      <Desc2>
        このサービスは、
        <Number fontSize="xx-large">1</Number>の<Number fontSize="xx-large">2</Number>
        という課題を
        <Number fontSize="xx-large">3</Number>
        の手段で
        <Number fontSize="xx-large">4</Number>
        という状態を実現します。
      </Desc2>

      <FormTitle>
        <Number fontSize="large">1</Number> サービスの「対象・顧客」を記載しましょう。
      </FormTitle>
      <Controller
        name="target"
        control={control}
        render={({ field }) => <FormTextArea {...field} rows={4} placeholder="例：〇〇〇〇" />}
      />

      <FormTitle>
        <Number fontSize="large">2</Number> ①の対象者の解決したい課題を記載しましょう。
      </FormTitle>
      <Controller
        name="issue"
        control={control}
        render={({ field }) => <FormTextArea {...field} rows={4} placeholder="例：〇〇〇〇" />}
      />

      <FormTitle>
        <Number fontSize="large">3</Number> ④を実現する現時点の手段（アイディア）を記載しましょう。
      </FormTitle>
      <Controller
        name="solution"
        control={control}
        render={({ field }) => <FormTextArea {...field} rows={4} placeholder="例：〇〇〇〇" />}
      />

      <FormTitle>
        <Number fontSize="large">4</Number> 実現したい状態を記載しましょう。
      </FormTitle>
      <Controller
        name="state"
        control={control}
        render={({ field }) => <FormTextArea {...field} rows={4} placeholder="例：〇〇〇〇" />}
      />

      <ActionButton bgColor="secondary-dark" style={{ margin: '40px auto 0' }} onClick={onSubmit}>
        上記の内容で募集内容の入力を始める
      </ActionButton>

      {progressModal.createModal(
        <DisplayProgress
          text="募集内容の下書きを作成しています…"
          progress={progress}
          transitionDuration={completed ? '0.5s' : '45s'} // 完了時はグッと速く
        />,
        { onClose },
      )}

      {completeModal.createModal(<DraftProjectComplete />)}
    </Wrapper>
  );
};
