import { useNavigate } from 'react-router-dom';
import { TabOption } from '../../elements/Tabs/Tabs';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { useModal } from '../../screens/modals/useModal';
import {
  IProject,
  PROJECT_CATEGORY,
  PROJECT_STATUS_NAMES,
  SPREADY_CHECK_NAMES,
} from 'domain/entities/Project/Project';
import { CompanyProjectQuery } from 'interfaceAdapter/queries/CompanyProject';
import { truncate } from 'utils/string';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';

export const TAB_OPTION_VALUES = {
  SELF: 'self',
  ONLY_ONE: 'only_one',
  ALL: 'all',
} as const;
export type ProjectListTab = (typeof TAB_OPTION_VALUES)[keyof typeof TAB_OPTION_VALUES];
const tabOptions: TabOption<ProjectListTab>[] = [
  {
    label: '自身が担当しているセッションのみ',
    value: TAB_OPTION_VALUES.SELF,
  },
  {
    label: '担当者が1名のセッションのみ',
    value: TAB_OPTION_VALUES.ONLY_ONE,
  },
  {
    label: '全て',
    value: TAB_OPTION_VALUES.ALL,
  },
];

export interface ProjectListTableRow {
  id: number;
  title: string;
  casting_count: number;
  is_event: boolean;
  create_name: string;
  management_users: ICompanyUser[];
  manager_name: string;
  status: string;
  spready_check: string;
  is_open?: boolean;
  share_enable: boolean;
}

const createManagerName = (managementUsers: ICompanyUser[]) => {
  if (managementUsers.length === 0) {
    return '';
  }
  if (managementUsers.length === 1) {
    return `${managementUsers[0].last_name} ${managementUsers[0].first_name}`;
  }
  return `${managementUsers[0].last_name} ${managementUsers[0].first_name} 他${
    managementUsers.length - 1
  }名`;
};

export const useProjectListTable = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyProjectQuery = diContainer.resolve<CompanyProjectQuery>(CompanyProjectQuery);
  const { user } = useContext(companyUserContext);
  const { openModal, createModal } = useModal();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [selectedTab, setSelectedTab] = useState<ProjectListTab>(TAB_OPTION_VALUES.SELF);
  const [previewProjectId, setPreviewProjectId] = useState<number | null>(null);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const formattedProjects = useMemo(() => {
    return projects.map((p) => ({
      id: p.id as number,
      title: truncate(p.title, 40),
      casting_count: p.casting_count ?? 0,
      is_event: p.category === PROJECT_CATEGORY.EVENT,
      create_name: `${p.create_user?.last_name ?? ''} ${p.create_user?.first_name ?? ''}`,
      management_users: p.management_users ?? [],
      manager_name: createManagerName(p.management_users ?? []),
      status: p.status ? PROJECT_STATUS_NAMES[p.status] : '',
      spready_check: p.spready_check ? SPREADY_CHECK_NAMES[p.spready_check] : '',
      is_open: p.isOpen,
      share_enable: p.share_enable,
    }));
  }, [projects]);

  const filteredProject = useMemo(() => {
    if (selectedTab === TAB_OPTION_VALUES.SELF) {
      return formattedProjects.filter((project) =>
        project.management_users?.some((u) => u.id === user.id),
      );
    }
    if (selectedTab === TAB_OPTION_VALUES.ONLY_ONE) {
      return formattedProjects.filter((project) => project.management_users?.length === 1);
    }
    if (selectedTab === TAB_OPTION_VALUES.ALL) {
      return formattedProjects;
    }
    return formattedProjects;
  }, [formattedProjects, selectedTab]);

  const gotoCreateProjectPage = useCallback(
    (id?: number) => navigate(`/company/main/projects/create/${id ?? ''}${id ? '/' : ''}`),
    [],
  );
  const gotoEditProjectPage = useCallback(
    (id: number) => navigate(`/company/main/projects/edit/${id}/`),
    [],
  );
  const openPreview = useCallback(
    (id: number) => {
      setPreviewProjectId(id);
      openModal();
    },
    [openModal],
  );
  const copyUrl = useCallback(
    (id: number) => {
      navigator.clipboard
        .writeText(`${window.location.protocol}//${window.location.host}/projects/${id}/`)
        .then(() => {
          setShowSuccessSnackbar(true);
          setSuccessMessage('URLをコピーしました');
        });
    },
    [setShowSuccessSnackbar, setSuccessMessage],
  );

  useEffect(() => {
    companyProjectQuery.getAll().then((projects) => setProjects(projects));
  }, []);

  return {
    tabOptions,
    projects: filteredProject,
    gotoCreateProjectPage,
    gotoEditProjectPage,
    openPreview,
    createModal,
    copyUrl,
    selectedTab,
    setSelectedTab,
    previewProjectId,
    showSuccessSnackbar,
    successMessage,
    setShowSuccessSnackbar,
  };
};
