import { useContext, useEffect, useState } from 'react';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserCheckToken } from 'application/usecases/CompanyUserCheckToken';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { useNavigate } from 'react-router-dom';

export const useCompanyUserConfirm = () => {
  const diContainer = useContext(diContainerContext);
  const { user, setUser } = useContext(companyUserContext);
  const companyUserCheckToken = diContainer.resolve(CompanyUserCheckToken);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const onConfirm = async () => {
    setRequesting(true);
    try {
      await companyUserRepository.confirmRegistration();
      setShowSnackbar(true);
      setTimeout(() => navigate('/company/registration/complete'), 1500);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result !== false) {
        setUser(result);
      } else {
        // トークン切れ。ログイン画面へ
        navigate('/company/');
      }
    };
    initialFetch();
  }, []);

  return {
    user,
    requesting,
    showSnackbar,
    setShowSnackbar,
    onConfirm,
  };
};
