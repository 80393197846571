import yup from 'utils/yup';
import { Message } from '../Message/Message';

export const messageSchema = yup.object({
  user_type: yup.string().oneOf(['u', 'c']).required(),
  user_id: yup.number().required(),
  message: yup.string().required(),
  user_last_name: yup.string().required(),
  user_first_name: yup.string().required(),
  user_icon_image: yup.string().required().nullable(),
  user_company_name: yup.string().required().nullable(),
  datetime: yup.string().required(),
  timestamp: yup.number().required(),
});

type MessageSchema = yup.InferType<typeof messageSchema>;

export interface IMessageFactory {
  build(data: MessageSchema): Message;
}

export class MessageFactory implements IMessageFactory {
  public build(data: MessageSchema): Message {
    return new Message(
      data.user_type,
      data.user_id,
      data.message,
      data.user_last_name,
      data.user_first_name,
      data.user_icon_image,
      data.user_company_name,
      new Date(data.datetime),
      data.timestamp,
    );
  }
}
