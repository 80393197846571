import * as yup from 'yup';
import {
  COMPANY_USER_ROLE,
  REGISTRATION_STEPS,
  CompanyUser,
} from 'domain/entities/CompanyUser/CompanyUser';
import { CompanyFactory } from './Company';

/**
 * 企業ユーザー ユーザー情報入力フォーム
 */
export const companyUserFormSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  icon_image: yup.string().nullable(),
  company_name: yup.string().required(),
  corporate_number: yup.string().nullable(),
  zipcode: yup.string().required(),
  address: yup.string().required(),
  department: yup.string().required(),
  job: yup.string().nullable().defined(),
  /*
   * このあたりは通るようにしてある
   * +81-90-0000-0000
   * 81-90-0000-0000
   * 090-0000-0000
   * 03-1234-1234
   */
  phone_number: yup
    .string()
    .required()
    .matches(/^[0-9+]{1,5}(?:-\d{1,5})?-\d{3,5}-\d{3,5}$/, {
      message: 'ハイフンありの半角数字でご入力ください',
    }),
  referral_code: yup.string().max(20).nullable().defined(),
});

export const companyUserWithoutApiTokenSchema = yup.object().shape({
  id: yup.number().required(),
  company: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().required(),
      status: yup.number().required(),
      address: yup.string().required(),
      capital: yup.string().nullable(),
      employee: yup.string().nullable(),
      icon_image: yup.string().nullable(),
      president_name: yup.string().nullable(),
      url_ogps: yup.array().of(yup.string().required()).required(),
      url: yup.string().required(),
      zipcode: yup.string().required(),
      herp_webhook_url: yup.string().nullable(),
      copy: yup.string().nullable(),
      pics: yup.array().of(yup.string().required()).required(),
      about_company: yup.string().nullable(),
      slack_webhook_url: yup.string().nullable(),
      search_words: yup.string().nullable(),
      contract_status: yup.number().required(),
      contract_period: yup.object().shape({
        start_date: yup.string().required(),
        end_date: yup.string().required(),
      }),
      plan: yup.object().shape({
        id: yup.number().required(),
        key: yup.string().required(),
        display_name: yup.string().required(),
        number_of_published_projects: yup.number().required(),
      }),
    })
    .required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  last_name_kana: yup.string().required().nullable(),
  first_name_kana: yup.string().required().nullable(),
  fb_user_id: yup.string().optional(),
  enable_fb_message: yup.boolean().required(),
  email: yup.string().required(),
  birthday: yup.string().required().nullable(),
  gender: yup.number().required(),
  icon_image: yup.string().nullable(),
  status: yup.number().required(),
  role: yup.number().oneOf(Object.values(COMPANY_USER_ROLE)).required(),
  department: yup.string().required(),
  job: yup.string().optional(),
  mail_news_receive: yup.boolean().required(),
  role_type: yup.string().required(),
  phone_number: yup.string().optional(),
  referral_code: yup.string().optional(),
  registration_step: yup.string().oneOf(Object.values(REGISTRATION_STEPS)).optional(),
});

export const companyUserSchema = companyUserWithoutApiTokenSchema.shape({
  api_token: yup.string().required(),
});
export type CompanyUserWithoutApiTokenSchema = yup.InferType<
  typeof companyUserWithoutApiTokenSchema
>;
export type CompanyUserSchema = yup.InferType<typeof companyUserSchema>;

/**
 * プロパティの少ない企業ユーザー（Project.management_user や ChatRoom.fromCompanyUser など）
 */
export const partialCompanyUserSchema = yup.object({
  id: yup.number().required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  icon_image: yup.string().nullable().default(null),
  status: yup.number().required(),
  company: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().required(),
      icon_image: yup.string().nullable().default(null),
      logo_display_pushed: yup.boolean().required(),
      search_words: yup.string().nullable().default(null),
      status: yup.number().required(),
    })
    .required(),
  job: yup.string().nullable().default(null),
  department: yup.string().required(),
  phone_number: yup.string().optional(),
});

export type PartialCompanyUserSchema = yup.InferType<typeof partialCompanyUserSchema>;

export interface ICompanyUserFactory {
  buildEmpty(): CompanyUser;
  build(data: CompanyUserSchema | CompanyUserWithoutApiTokenSchema): CompanyUser;
  buildByPartial(data: PartialCompanyUserSchema): CompanyUser;
}

export class CompanyUserFactory implements ICompanyUserFactory {
  public buildEmpty(): CompanyUser {
    return new CompanyUser(
      0,
      new CompanyFactory().buildEmpty(),
      '',
      '',
      '',
      '',
      '',
      false,
      '',
      null,
      '',
      0,
      '',
      0,
      COMPANY_USER_ROLE.USER,
      '',
      '',
      true,
      '',
      false,
      '',
      '',
      REGISTRATION_STEPS.NOTHING,
    );
  }

  public build(data: CompanyUserSchema | CompanyUserWithoutApiTokenSchema): CompanyUser {
    return new CompanyUser(
      data.id,
      // @ts-expect-error ICompanyと微妙に異なるため
      data.company,
      data.last_name,
      data.first_name,
      data.last_name_kana,
      data.first_name_kana,
      data.fb_user_id,
      data.enable_fb_message,
      data.email,
      '',
      data.birthday,
      data.gender,
      data.icon_image,
      data.status,
      data.role,
      data.department,
      data.job,
      data.mail_news_receive,
      data.role_type,
      false,
      data?.phone_number ?? '',
      data?.referral_code ?? '',
      data?.registration_step ?? REGISTRATION_STEPS.NOTHING,
    );
  }

  public buildByPartial = (data: PartialCompanyUserSchema): CompanyUser => {
    return new CompanyUser(
      data.id,
      // @ts-expect-error 本来は CompanyFactory.build() したい
      data.company,
      data.last_name,
      data.first_name,
      '',
      '',
      '',
      false,
      '',
      null,
      '',
      0,
      data.icon_image,
      data.status,
      COMPANY_USER_ROLE.USER,
      data.department,
      data.job,
      false,
      '',
      false,
      data.phone_number,
      '',
      REGISTRATION_STEPS.NOTHING,
    );
  };
}
