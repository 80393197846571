import { useNavigate } from 'react-router-dom';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { COMPANY_CONTRACT_STATUS } from 'domain/entities/Company/Company';
import { IDraftProject } from 'domain/entities/DraftProject/DraftProject';
import { CompanyDraftProjectQuery } from 'interfaceAdapter/queries/CompanyDraftProject';
import { CompanyProjectRepository } from 'interfaceAdapter/repositories/CompanyProject';
import { truncate } from 'utils/string';

export interface DraftProjectListTableRow {
  id: number;
  title: string;
  create_name: string;
}

export const useDraftProjectListTable = () => {
  const navigate = useNavigate();
  const deleteConfirmModal = useModal();
  const diContainer = useContext(diContainerContext);
  const companyDraftProjectQuery =
    diContainer.resolve<CompanyDraftProjectQuery>(CompanyDraftProjectQuery);
  const { user } = useContext(companyUserContext);
  const [draftProjects, setDraftProjects] = useState<IDraftProject[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState<number | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (showDeleteConfirm) {
      deleteConfirmModal.openModal();
    } else {
      deleteConfirmModal.closeModal();
    }
  }, [showDeleteConfirm]);

  const formattedProjects = useMemo(() => {
    return draftProjects.map((p) => ({
      id: p.id as number,
      title: p.title ? truncate(p.title, 40) : 'タイトル未設定',
      create_name: `${p.create_user?.last_name ?? ''} ${p.create_user?.first_name ?? ''}`,
    }));
  }, [draftProjects]);

  const showCreateProjectButton = useMemo(
    () => user.company.contract_status === COMPANY_CONTRACT_STATUS.CONTRACTED,
    [user],
  );

  const gotoEditProjectPage = useCallback(
    (id: number) => navigate(`/company/main/draft/projects/edit/${id}/`),
    [],
  );

  const filteredProject = useMemo(() => {
    return formattedProjects;
  }, [formattedProjects]);

  const fetchCompanyDraftProject = async () => {
    const response = await companyDraftProjectQuery.getAll();
    setDraftProjects(response.data);
  };

  const gotoPrepareProjectPage = useCallback(() => navigate('/company/main/projects/prepare/'), []);

  useEffect(() => {
    fetchCompanyDraftProject();
  }, []);

  const onClickDeleteButton = (deleteProjectId: number) => {
    setDeleteProjectId(deleteProjectId);
    setShowDeleteConfirm(true);
  };

  const onCancelDelete = () => {
    setDeleteProjectId(null);
    setShowDeleteConfirm(false);
  };

  const deleteProject = async () => {
    if (!deleteProjectId) return;
    const companyProjectRepository =
      diContainer.resolve<CompanyProjectRepository>(CompanyProjectRepository);
    await companyProjectRepository.deleteDraft(deleteProjectId);
    await fetchCompanyDraftProject();
    onCancelDelete();
    setShowSnackbar(true);
  };

  return {
    draftProjects: filteredProject,
    gotoEditProjectPage,
    showCreateProjectButton,
    gotoPrepareProjectPage,
    deleteConfirmModal,
    showSnackbar,
    setShowSnackbar,
    onCancelDelete,
    onClickDeleteButton,
    deleteProject,
  };
};
