import yup from 'utils/yup';
import { COMPANY_USER_ROLE, CompanyUserRole } from 'domain/entities/CompanyUser/CompanyUser';
import { IPreCompanyUser, PreCompanyUser } from 'domain/entities/CompanyUser/PreCompanyUser';

export const preCompanyUserSchema = yup.object({
  id: yup.number().required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  email: yup.string().required(),
  role: yup.number().oneOf(Object.values(COMPANY_USER_ROLE)).required(),
  job: yup.string().nullable(),
  department: yup.string().required(),
  role_type: yup.string().required(),
  expired_at: yup.date().nullable(),
});

export type IPreCompanyUserInput = Readonly<yup.InferType<typeof preCompanyUserSchema>>;

export interface IPreCompanyUserFactory {
  build(data: IPreCompanyUserInput): IPreCompanyUser;
}

export class PreCompanyUserFactory implements IPreCompanyUserFactory {
  public build(data: IPreCompanyUserInput): IPreCompanyUser {
    return new PreCompanyUser(
      data.id,
      null,
      data.last_name,
      data.first_name,
      data.email,
      data.role as CompanyUserRole,
      data.job ?? '',
      data.department,
      data.role_type,
      data.expired_at ? new Date(data.expired_at) : null,
    );
  }
}
