import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { COMPANY_USER_ROLE, ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';

const schema = yup
  .object()
  .shape(
    {
      icon_image: yup.string().default(''),
      last_name: yup.string().default('').required(),
      first_name: yup.string().default('').required(),
      email: yup.string().email().default('').required(),
      password: yup.string().when('password', {
        is: (password: string) => password === '',
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.default('').min(4).required(),
      }),
      role: yup.number().oneOf(Object.values(COMPANY_USER_ROLE)).required(),
      department: yup.string().default('').required(),
      job: yup.string().default(''),
      /*
       * このあたりは通るようにしてある
       * +81-90-0000-0000
       * 81-90-0000-0000
       * 090-0000-0000
       * 03-1234-1234
       */
      phone_number: yup
        .string()
        .required()
        .matches(/^[0-9+]{1,5}(?:-\d{1,5})?-\d{3,5}-\d{3,5}$/, {
          message: 'ハイフンありの半角数字でご入力ください',
        }),
    },
    [['password', 'password']],
  )
  .required();

type UserEditForm = yup.InferType<typeof schema>;

export const useUserEdit = () => {
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve<CompanyUserRepository>(CompanyUserRepository);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  /** 現在ログイン中のユーザー */
  const { user: editor } = useContext(companyUserContext);
  const navigate = useNavigate();
  const params = useParams();
  const { control, handleSubmit, formState, setValue, watch } = useForm<UserEditForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  /** 編集対象のユーザー */
  const [targetUser, setTargetUser] = useState<ICompanyUser | null>(null);
  const [requesting, setRequesting] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const selectableRoles = useMemo(() => {
    if (!targetUser) {
      return [];
    }
    // サポートユーザーを変更することはできない
    if (targetUser.role === COMPANY_USER_ROLE.SUPPORT_USER) {
      return [{ value: COMPANY_USER_ROLE.SUPPORT_USER, label: 'サポートユーザ' }];
    }
    // スーパーユーザーからはユーザーorスーパーユーザーに変更可能
    if (targetUser.role === COMPANY_USER_ROLE.SUPER_USER) {
      return [
        { value: COMPANY_USER_ROLE.USER, label: 'ユーザ' },
        { value: COMPANY_USER_ROLE.SUPER_USER, label: 'スーパーユーザ' },
      ];
    }
    // ユーザーはユーザーを変更できない
    if (targetUser.role === COMPANY_USER_ROLE.USER && editor.role === COMPANY_USER_ROLE.USER) {
      return [{ value: COMPANY_USER_ROLE.USER, label: 'ユーザ' }];
    }
    // ユーザー以上の権限を保つ場合、ユーザーをユーザーorスーパーユーザーに変更可能
    if (targetUser.role === COMPANY_USER_ROLE.USER && editor.role > COMPANY_USER_ROLE.USER) {
      return [
        { value: COMPANY_USER_ROLE.USER, label: 'ユーザ' },
        { value: COMPANY_USER_ROLE.SUPER_USER, label: 'スーパーユーザ' },
      ].filter((role) => role.value <= editor.role);
    }
    return [];
  }, [targetUser?.role, editor.role]);

  const onImageUpload = useCallback(async (fileUrl: string) => {
    setValue('icon_image', fileUrl);
  }, []);

  const onSubmit = handleSubmit(async (form) => {
    setRequesting(true);
    try {
      await companyUserRepository.edit({
        id: params.userId ?? '',
        icon_image: form.icon_image === '' ? null : form.icon_image,
        last_name: form.last_name,
        first_name: form.first_name,
        email: form.email,
        password: form.password,
        role: form.role,
        department: form.department,
        job: form.job === '' ? null : form.job,
        phone_number: form.phone_number,
      });
      setShowSuccessSnackbar(true);
      setTimeout(() => navigate('/company/main/users/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    const id = parseInt(params.userId ?? '', 10);
    if (isNaN(id)) {
      return;
    }
    companyUserQuery.get(id).then((target) => {
      setTargetUser(target);
      setValue('icon_image', target.icon_image ?? '');
      setValue('last_name', target.last_name);
      setValue('first_name', target.first_name);
      setValue('email', target.email);
      setValue('password', '');
      setValue('role', target.role);
      setValue('department', target.department ?? '');
      setValue('job', target.job ?? '');
      setValue('phone_number', target.phone_number ?? '');
    });
  }, []);

  return {
    editor,
    targetUser,
    control,
    watch,
    formState,
    selectableRoles,
    requesting,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    onImageUpload,
    onSubmit,
  };
};
