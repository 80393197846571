import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { FormCheckbox } from 'ui/components/company/elements/FormCheckbox/FormCheckbox';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { CustomLink } from 'ui/components/publicPages/elements/CustomLink/CustomLink';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { useSendEmail } from './useSendEmail';

const Wrapper = styled.div`
  padding: 40px 30px;
  border-radius: 12px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: var(--font-size--medium);
`;

const Title = styled.div`
  font-size: var(--font-size--xx-large);
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const CheckContainer = styled.div`
  margin: 0 auto;
`;

const CompleteText = styled.div`
  margin: 0;
  font-size: var(--font-size--medium);
  line-height: 1.5;
`;

const PreviewEmail = styled.div`
  width: 100%;
  height: 45px;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--gray-light-3);
`;

const LoginLink = styled.div`
  position: relative;
  text-align: center;
  margin-top: 32px;
  padding-top: 48px;
  font-size: var(--font-size--default);

  &:before {
    content: '.....';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    letter-spacing: 0.5em;
    color: var(--gray);
  }
`;

export const SendEmailForm: React.FC = () => {
  const { control, loading, errors, complete, sendMail, getValues } = useSendEmail();

  return (
    <Wrapper>
      {complete ? (
        <>
          <Title>確認用メールを送信しました</Title>
          <CompleteText>
            以下のメールアドレスに確認用のメールを送信しました。
            <br />
            メールに記載されている認証URLをクリックして、本登録を行なってください。
          </CompleteText>
          <PreviewEmail>{getValues('email')}</PreviewEmail>
        </>
      ) : (
        <>
          <Title>メールアドレスで登録</Title>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <div>
                <FormInput
                  {...field}
                  placeholder="example@spready.jp"
                  hasError={!!errors.email?.message}
                />
                {errors.email?.message && <FormError message={errors.email.message} />}
              </div>
            )}
          />

          <CheckContainer>
            <Controller
              name="agreed"
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  id="agreed"
                  checked={field.value}
                  onChange={() => field.onChange(!field.value)}
                  disableBorder
                  hasError={!!errors.agreed?.message}
                >
                  <CustomLink
                    inline
                    textDecoration="underline"
                    url="/company-application/terms/"
                    text="利用規約"
                    target="_blank"
                  />
                  及び
                  <CustomLink
                    inline
                    textDecoration="underline"
                    url="/info/policy/"
                    text="個人情報の取り扱い"
                    target="_blank"
                  />
                  に同意
                </FormCheckbox>
              )}
            />
            {errors.agreed?.message && <FormError message={errors.agreed.message} />}
          </CheckContainer>
          <ActionButton disabled={loading} onClick={sendMail} bgColor="secondary-dark">
            登録する（無料）
          </ActionButton>
          <LoginLink>
            <CustomLink inline url="/company/" text="ログイン（会員登録がお済みの方）はこちら" />
          </LoginLink>
        </>
      )}
    </Wrapper>
  );
};
