import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import dayjs from 'dayjs';
import { COMPANY_CONTRACT_STATUS } from 'domain/entities/Company/Company';
import { COMPANY_USER_ROLE, ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { IProjectForm, ProjectFactory, projectFormSchema } from 'domain/entities/factories/Project';
import { IDraftProjectForm } from 'domain/entities/factories/DraftProject';
import { IProject, PROJECT_CATEGORY, PROJECT_TITLE_OPINION } from 'domain/entities/Project/Project';
import { CompanyProjectQuery } from 'interfaceAdapter/queries/CompanyProject';
import { CompanyDraftProjectQuery } from 'interfaceAdapter/queries/CompanyDraftProject';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { CompanyProjectRepository } from 'interfaceAdapter/repositories/CompanyProject';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// todo: 古いカテゴリを消したら、Object.values(PROJECT_CATEGORY) に変更する
const projectCategoryOptions = Object.values([
  PROJECT_CATEGORY.USER_INTERVIEW,
  PROJECT_CATEGORY.CONSULT_EXPERT,
  PROJECT_CATEGORY.BUSINESS_MATCHING,
  PROJECT_CATEGORY.OTHER,
]).map((key) => ({ value: key }));

const privacyOptions = [
  { value: 0, label: '社名を公開する' },
  { value: 1, label: '社名を非公開にする' },
];

export const useSessionManageForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const diContainer = useContext(diContainerContext);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  const companyProjectRepository =
    diContainer.resolve<CompanyProjectRepository>(CompanyProjectRepository);
  const companyProjectQuery = diContainer.resolve<CompanyProjectQuery>(CompanyProjectQuery);
  const companyDraftProjectQuery =
    diContainer.resolve<CompanyDraftProjectQuery>(CompanyDraftProjectQuery);
  const { user } = useContext(companyUserContext);
  const deleteConfirmModal = useModal();
  const { control, handleSubmit, formState, getValues, setValue, watch, trigger } =
    useForm<IProjectForm>({
      resolver: yupResolver(projectFormSchema),
      mode: 'onChange',
      defaultValues: projectFormSchema.cast({}),
    });
  const formValues = watch();

  const [project, setProject] = useState<IProject>(new ProjectFactory().buildEmptyProject());
  const [projectForPreview, setProjectForPreview] = useState<IProject>(
    new ProjectFactory().buildEmptyProject(),
  );
  const [companyUsers, setCompanyUsers] = useState<ICompanyUser[]>([]);
  const [requesting, setRequesting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [draftId, setDraftId] = useState<number | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const arrayFields = {
    targets: useFieldArray({ control, name: 'targets' }),
    themes: useFieldArray({ control, name: 'themes' }),
    match_points: useFieldArray({ control, name: 'match_points' }),
  };
  const appendField = useCallback((field: 'targets' | 'themes' | 'match_points') => {
    arrayFields[field].append({ text: '' });
  }, []);
  const removeEmptyFields = useCallback((field: 'targets' | 'themes' | 'match_points') => {
    // 削除対象のindex取得
    const target = getValues(field).reduce<number[]>((acc, field, index) => {
      if (index > 0 && field.text === '') {
        return [...acc, index];
      }
      return acc;
    }, []);
    // 後ろから削除
    target.reverse().forEach((index) => arrayFields[field].remove(index));
  }, []);

  // 離脱警告の表示
  const showPrompt = useMemo(() => {
    return formState.isDirty && !completed;
  }, [formState.isDirty, completed]);

  const isNewProject = useMemo(
    () => location.pathname.startsWith('/company/main/projects/create'),
    [location.pathname],
  );
  const isCopyMode = useMemo(() => isNewProject && params.projectId, [isNewProject, params]);

  const fileName = useMemo(() => {
    return `${Math.floor(Math.random() * 100000000)}_${user.company.id}_${user.id}`;
  }, [user]);

  const companyUserOptions = useMemo(() => {
    return companyUsers.map((c) => ({
      value: c.id,
      label: `${c.last_name} ${c.first_name}`,
    }));
  }, [companyUsers]);

  const onImageUpload = useCallback(async (fileUrl: string) => {
    setValue('main_pic', fileUrl);
    trigger('main_pic');
  }, []);

  // 公開保存
  const onPublicSave = async (form: IProjectForm) => {
    setRequesting(true);
    try {
      const param = {
        id: project.id,
        ...form,
      };
      if (draftId) {
        param.draft_id = draftId;
      }
      const res =
        isNewProject || draftId
          ? await companyProjectRepository.save(param)
          : await companyProjectRepository.edit(param);

      const completeUrl = param.share_enabled
        ? `/company/main/projects/complete/${res.id}/`
        : `/company/main/projects/share-disable/complete/${res.id}/`;
      setCompleted(true);
      setSnackbarMessage('登録しました');
      setTimeout(() => navigate(completeUrl), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  };

  // 下書き保存
  const onDraftSave = async (form: IDraftProjectForm) => {
    if (!draftId || user.role === COMPANY_USER_ROLE.ADMIN_USER) {
      return;
    }
    setRequesting(true);
    try {
      const param = {
        ...form,
        draft_id: draftId,
      };
      await companyProjectRepository.editDraft(param);

      setCompleted(true);
      setSnackbarMessage('下書き保存しました');
      setTimeout(() => navigate('/company/main/projects/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  };

  const onSubmit = (makeOpen?: boolean) => async () => {
    const isValid = await trigger();

    if (!isValid && makeOpen) {
      setSnackbarMessage('入力エラーがあります。入力内容をご確認ください。');
      return;
    }

    if (makeOpen) {
      await handleSubmit(async (form) => {
        if (user.role === COMPANY_USER_ROLE.ADMIN_USER) {
          return;
        }

        return onPublicSave(form);
      })();
    } else {
      // @ts-expect-error 下書き保存の場合はバリデーションチェックをIDraftProjectFormに合わせたい
      return onDraftSave(getValues());
    }
  };

  const makePending = useCallback(async () => {
    if (!project.id || !confirm('こちらのセッションの募集を一時停止しますか？')) {
      return;
    }
    setRequesting(true);
    try {
      await companyProjectRepository.makePending(project.id);
      setSnackbarMessage('セッションの募集を一時停止しました');
      setTimeout(() => navigate('/company/main/projects/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  }, [project.id]);

  const reopen = handleSubmit(async (form) => {
    if (!confirm('こちらのセッションを掲載再開しますか？')) {
      return;
    }
    setRequesting(true);
    try {
      await companyProjectRepository.reopen({
        id: project.id,
        spready_check: project.spready_check,
        ...form,
      });
      setSnackbarMessage('セッションを掲載再開しました');
      setTimeout(() => navigate('/company/main/projects/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  });

  const close = useCallback(async () => {
    if (
      !project.id ||
      !confirm(
        'こちらのセッションを掲載終了しますか？募集を終了すると募集再開はできません。再開する可能性がある場合は「募集を一時停止する」を選択してください',
      )
    ) {
      return;
    }
    setRequesting(true);
    try {
      await companyProjectRepository.close(project.id);
      setSnackbarMessage('セッションを掲載終了しました');
      setTimeout(() => navigate('/company/main/projects/'), 1500);
    } catch (e) {
      // @ts-expect-error エラーの型がunknownなので
      throw new Error(e.message ?? '登録に失敗しました');
    } finally {
      setRequesting(false);
    }
  }, [project.id]);

  const canAccessForm = () => {
    if (isNewProject && user.company.contract_status !== COMPANY_CONTRACT_STATUS.CONTRACTED) {
      setSnackbarMessage('契約中ではないためセッションの新規作成は行なえません。');
      setTimeout(() => navigate('/company/main/projects/'), 1500);
    }
    if (user.company.contract_status === COMPANY_CONTRACT_STATUS.NOT_IN_SERVICE) {
      setSnackbarMessage('契約期間外のためセッションの編集は行なえません。');
      setTimeout(() => navigate('/company/main/projects/'), 1500);
    }
  };

  const createDraftProject = async () => {
    const { data } = await companyProjectRepository.createDraft();
    setDraftId(data.draft_id);
  };

  const deleteDraftProject = async () => {
    if (!draftId) return;
    const companyProjectRepository =
      diContainer.resolve<CompanyProjectRepository>(CompanyProjectRepository);
    await companyProjectRepository.deleteDraft(draftId);
    setShowDeleteConfirm(false);
    setCompleted(true);
    setSnackbarMessage('下書きセッションを削除しました');
    setTimeout(() => navigate('/company/main/projects/'), 1500);
  };

  useEffect(() => {
    canAccessForm();
    if (params.projectId) {
      isCopyMode && createDraftProject();

      companyProjectQuery.get(parseInt(params.projectId, 10)).then((p) => {
        const targetPerson = isCopyMode
          ? `【複製】${p.target_person ?? ''}`
          : p.target_person ?? '';
        setProject({
          ...p,
          target_person: targetPerson,
        });
        setValue('start_at', p.start_at ? dayjs(p.start_at).format('YYYY/M/D') : '');
        setValue('end_at', p.end_at ? dayjs(p.end_at).format('YYYY/M/D') : '');
        setValue('management_user_ids', p.management_users?.map((u) => u.id) ?? []);
        setValue('category', p.category);
        setValue('main_pic', p.main_pic);
        setValue('target_person', targetPerson);
        setValue('topic_of_interest', p.topic_of_interest ?? '');
        setValue('title_complement_text', p.title_complement_text ?? '');
        setValue('title_opinion_type', p.title_opinion_type ?? PROJECT_TITLE_OPINION.ASK);
        setValue('targets', p.targets?.map((t) => ({ text: t })) ?? []);
        setValue('themes', p.themes?.map((t) => ({ text: t })) ?? []);
        setValue('background', p.background ?? '');
        setValue('goal', p.goal ?? '');
        setValue('outro', p.outro ?? '');
        setValue('match_points', p.match_points?.map((t) => ({ text: t })) ?? []);
        setValue('match_point_text', p.match_point_text ?? '');
        setValue('share_enabled', p.share_enable ?? true);
        setValue('enable_offline_interview', p.enable_offline_interview ?? true);
        setValue('chat_template_text', p.chat_template_text ?? '');
        setValue('summary', p.summary ?? '');
        setValue('number_of_people', p.number_of_people === null ? undefined : p.number_of_people);
        setValue('privacy', p.privacy ? 1 : 0);
      });
    } else if (params.assetsId) {
      createDraftProject();
      companyProjectQuery.getDraftAsset(params.assetsId).then((asset) => {
        asset.content.forEach((item) => {
          // 本来はAPI側できちんとフォーマットしておくべきだが簡易的にここでやっている
          switch (item.key) {
            case 'summary':
            case 'target_person':
            case 'topic_of_interest':
            case 'background':
            case 'goal':
            case 'outro':
            case 'chat_template_text':
              setValue(item.key, item.generated_items[0] ?? '');
              break;
            case 'targets':
              setValue(item.key, item.generated_items?.map((t) => ({ text: t })) ?? []);
              // targetsとmatch_pointsに乖離がでてしまうケースで違和感があるとの感想をもらったので同じ値をセットしている
              setValue('match_points', item.generated_items?.map((t) => ({ text: t })) ?? []);
              break;
            case 'match_point_text':
              setValue(
                item.key,
                item.generated_items?.reduce((ac, cur) => ac + cur + '\n', '') ?? '',
              );
              break;
            case 'themes':
              setValue(item.key, item.generated_items?.map((t) => ({ text: t })) ?? []);
              break;
            default:
              break;
          }
        });
      });
    } else if (params.draftId) {
      // 下書きセッションの編集
      setDraftId(Number(params.draftId));
      companyDraftProjectQuery.get(Number(params.draftId)).then((result) => {
        const p = result.data;

        setValue('management_user_ids', p.management_users?.map((u) => u.id) ?? []);
        p.category && setValue('category', p.category);
        p.main_pic && setValue('main_pic', p.main_pic);
        p.target_person && setValue('target_person', p.target_person);
        setValue('topic_of_interest', p.topic_of_interest ?? '');
        setValue('title_complement_text', p.title_complement_text ?? '');
        setValue('title_opinion_type', p.title_opinion_type ?? PROJECT_TITLE_OPINION.ASK);
        setValue(
          'targets',
          p.targets.length > 0 ? p.targets?.map((t) => ({ text: t })) : [{ text: '' }],
        );
        setValue(
          'themes',
          p.themes.length > 0 ? p.themes?.map((t) => ({ text: t })) : [{ text: '' }],
        );
        setValue('background', p.background ?? '');
        setValue('goal', p.goal ?? '');
        setValue('outro', p.outro ?? '');
        setValue(
          'match_points',
          p.match_points.length > 0 ? p.match_points?.map((t) => ({ text: t })) : [{ text: '' }],
        );
        setValue('match_point_text', p.match_point_text ?? '');
        setValue('share_enabled', p.share_enable ?? true);
        setValue('enable_offline_interview', p.enable_offline_interview ?? true);
        setValue('chat_template_text', p.chat_template_text ?? '');
        setValue('summary', p.summary ?? '');
        setValue('number_of_people', p.number_of_people === null ? undefined : p.number_of_people);
        setValue('privacy', p.privacy ? 1 : 0);
      });
    } else {
      createDraftProject();
    }
    companyUserQuery.getAll().then((users) => setCompanyUsers(users));
  }, []);

  // プレビュー用のプロジェクトを作成
  useEffect(() => {
    const result = new ProjectFactory().buildPreviewProject({
      ...formValues,
      company: user.company,
      companyUsers,
    });
    setProjectForPreview(result);
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (showDeleteConfirm) {
      deleteConfirmModal.openModal();
    } else {
      deleteConfirmModal.closeModal();
    }
  }, [showDeleteConfirm]);

  return {
    companyUserOptions,
    privacyOptions,
    categoryOptions: projectCategoryOptions,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState,
    arrayFields,
    appendField,
    removeEmptyFields,
    showPrompt,
    project,
    projectForPreview,
    requesting,
    snackbarMessage,
    setSnackbarMessage,
    isNewProject,
    fileName,
    draftId,
    onImageUpload,
    onSubmit,
    makePending,
    reopen,
    close,
    deleteConfirmModal,
    showDeleteConfirm,
    setShowDeleteConfirm,
    deleteDraftProject,
  };
};
