import { useContext, useState } from 'react';
import type { IMeetRequestSurvey } from 'domain/entities/Survey/MeetRequestSurvey';
import { MeetRequest } from 'domain/entities/MeetRequest/MeetRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { MeetRequestCompanySurveyRepository } from 'interfaceAdapter/repositories/MeetRequestCompanySurvey';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { MeetRequestCompanySurveyQuery } from 'interfaceAdapter/queries/MeetRequestCompanySurvey';

export const useCompanyMeetRequestSurvey = () => {
  const [survey, setSurvey] = useState<IMeetRequestSurvey | null>(null);
  const [meetRequest, setMeetRequest] = useState<MeetRequest | null>(null);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const params = useParams();
  const meetRequestId = Number(params.meetRequestId);
  const diContainer = useContext(diContainerContext);
  const companySurveyQuery = diContainer.resolve(MeetRequestCompanySurveyQuery);
  const companySurveyRepository = diContainer.resolve(MeetRequestCompanySurveyRepository);
  const { setUrlAfterLogin } = useContext(companyUserContext);
  const navigate = useNavigate();

  const fetchSurveyDataForCompany = async () => {
    if (!meetRequestId) {
      return;
    }

    try {
      setLoading(true);
      const data = await companySurveyQuery.fetch(meetRequestId);

      if (!data) {
        throw new Error('アンケート情報の取得に失敗しました');
      }
      if ('survey' in data && 'meetRequest' in data) {
        setSurvey(data.survey);
        setMeetRequest(data.meetRequest);
      }
    } catch (e) {
      // @ts-expect-error eがunknown型なので
      if (e.status === 401) {
        // ログインが必要
        setUrlAfterLogin(`/survey/meet-request/company/answer/${meetRequestId}`);
        navigate('/company/');
      } else {
        navigate('/not_found/');
      }

      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const answerMeetRequestSurveyForCompany = async (answers: Array<number | string | null>) => {
    if (!meetRequestId) {
      return;
    }

    try {
      setLoading(true);
      await companySurveyRepository.answer(meetRequestId, answers);
      setComplete(true);
    } catch (e) {
      // エラー通知に気づかせるため
      window.scrollTo({ top: 0, behavior: 'smooth' });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    survey,
    meetRequest,
    complete,
    fetchSurveyDataForCompany,
    answerMeetRequestSurveyForCompany,
  };
};
