import { User, IUser, USER_IDENTITY, USER_STATUS } from 'domain/entities/User/User';
import { InferType } from 'yup';
import yup from 'utils/yup';
import { ISupportChatTitleUser, SupportChatTitleUser } from '../User/SupportChatTitleUser';

/**
 * ユーザー登録時のリクエストパラメータ
 */
export const registerUserParamsSchema = yup.object({
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  birthday: yup.string().required(),
  email: yup.string().required(),
  password: yup.string().nullable(), // facebook_sign_up_tokenがある場合は不要
  spreader_name: yup.string().nullable(),
  industry_segment_parent: yup.string().required(),
  industry_segment_child: yup.string().required(),
  job_segment_parent: yup.string().required(),
  job_segment_child: yup.string().required(),
  mail_news_receive: yup.boolean().required(),
  facebook_sign_up_token: yup.string().nullable(),
  cast_code: yup.string().nullable().optional(),
  cast_match_points: yup
    .array(
      yup.object({
        question: yup.string().required(),
        answer: yup.string().required(),
      }),
    )
    .optional(),
  intro_text: yup.string().nullable().optional(),

  // 以下、フォーム入力ではなく、コード上でデフォルト値を設定しているもの
  company_name: yup.string().nullable().optional(),
  company_job: yup.string().nullable().optional(),
  profiee_url: yup.string().nullable().optional(),
  facebook_url: yup.string().nullable().optional(),
  is_profiee_url_open: yup.boolean().nullable().optional(),
  is_facebook_url_open: yup.boolean().nullable().optional(),
  is_company_info_open: yup.boolean().nullable().optional(),
  comes_from: yup.string().oneOf(['WEBINAR']).optional(),
});

export type RegisterUserParamsSchema = InferType<typeof registerUserParamsSchema>;

/**
 * 認証チェックAPIのレスポンス
 */
export const checkTokenUserSchema = yup.object({
  id: yup.number().required(),
  spreader_name: yup.string().nullable(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  password: yup.string().nullable(),
  api_token: yup.string().required(),
  birthday: yup.string().nullable(),
  icon_image: yup.string().nullable(),
  identity: yup.number().required(),
  status: yup.number().required(),
  company_name: yup.string().nullable(),
  company_job: yup.string().nullable(),
  urls: yup.array(yup.string()).required(),
  mail_news_receive: yup.boolean().required(),
  social_capital_point: yup.number().nullable(),
  social_capital_ranking: yup.number().nullable(),
  age: yup.number().nullable(),
  tutorial_finished: yup.boolean().required(),
  is_company_info_open: yup.boolean().required(),
  has_new_stamps: yup.boolean().required(),
  has_new_feedback: yup.boolean().required(),
  industry_segment_parent: yup.string().nullable(),
  industry_segment_child: yup.string().nullable(),
  job_segment_parent: yup.string().nullable(),
  job_segment_child: yup.string().nullable(),
});

export type CheckTokenUserSchema = InferType<typeof checkTokenUserSchema> & {
  email?: string;
  profiee_url?: string | null;
  facebook_url?: string | null;
  is_profiee_url_open?: boolean;
  is_facebook_url_open?: boolean;
  tag_interest?: { id: number; title: string }[];
  links?: string[];
  job_news_recieve?: boolean;
  past_job_description?: string;
  skills?: { id: number; name: string }[];
  applying_for_change_email?: string | null;
};

/**
 * メール認証APIのレスポンス
 */
export const confirmEmailUserSchema = yup.object({
  id: yup.number().required(),
  email: yup.string().required(),
  spreader_name: yup.string().nullable(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  api_token: yup.string().required(),
  birthday: yup.string().nullable(),
  icon_image: yup.string().nullable(),
  identity: yup.number().required(),
  status: yup.number().required(),
  company_name: yup.string().nullable(),
  company_job: yup.string().nullable(),
  urls: yup.array(yup.string()).required(),
  mail_news_receive: yup.boolean().required(),
  social_capital_point: yup.number().required(),
  age: yup.number().nullable(),
  industry_segment_parent: yup.string().required(),
  industry_segment_child: yup.string().required(),
  job_segment_parent: yup.string().required(),
  job_segment_child: yup.string().required(),
});

export type ConfirmEmailUserSchema = InferType<typeof confirmEmailUserSchema> & {
  social_capital_ranking?: number | null;
  profiee_url?: string | null;
  facebook_url?: string | null;
  is_profiee_url_open?: boolean;
  is_facebook_url_open?: boolean;
  tutorial_finished?: boolean;
  is_company_info_open?: boolean;
  has_new_stamps?: boolean;
  has_new_feedback?: boolean;
  tag_interest?: { id: number; title: string }[];
  links?: string[];
  job_news_recieve?: boolean;
  past_job_description?: string;
  skills?: { id: number; name: string }[];
  applying_for_change_email?: string | null;
};

export const mySelfSchema = yup
  .object()
  .shape({
    id: yup.number().required(),
    spreader_name: yup.string().nullable().default(null),
    last_name: yup.string().required(),
    first_name: yup.string().required(),
    email: yup.string().required(),
    birthday: yup.string().required(),
    age: yup.number().required(),
    icon_image: yup.string().nullable().default(null),
    identity: yup.number().oneOf(Object.values(USER_IDENTITY)).required(),
    status: yup.number().oneOf(Object.values(USER_STATUS)).required(),
    api_token: yup.string().required(),
    company_name: yup.string().nullable().default(null),
    company_job: yup.string().nullable().default(null),
    urls: yup.array(yup.string()).required(),
    mail_news_receive: yup.boolean().required(),
    social_capital_point: yup.number().nullable().default(null),
    tutorial_finished: yup.boolean().required(),
    is_company_info_open: yup.boolean().required(),
    has_new_stamps: yup.boolean().required(),
    has_new_feedback: yup.boolean().required(),
    social_capital_ranking: yup.number().nullable().default(null),
    industry_segment_parent: yup.string().nullable().default(null),
    industry_segment_child: yup.string().nullable().default(null),
    job_segment_parent: yup.string().nullable().default(null),
    job_segment_child: yup.string().nullable().default(null),
    links: yup.array(yup.string().required()).required(),
    job_news_recieve: yup.boolean().required(),
    past_job_description: yup.string().nullable().default(null),
    skills: yup
      .array(yup.object().shape({ id: yup.number().required(), name: yup.string().required() }))
      .required(),
    applying_for_change_email: yup.string().nullable().defined(),
  })
  .required();

export type MySelfSchema = yup.InferType<typeof mySelfSchema> & {
  // User Entity にはあるが API から貰えない項目の型を補完している
  profiee_url?: string | null;
  facebook_url?: string | null;
  is_profiee_url_open?: boolean;
  is_facebook_url_open?: boolean;
  tag_interest?: { id: number; title: string }[];
};

const baseSchema = {
  id: yup.number().required(),
  spreader_name: yup.string().nullable(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  password: yup.string().nullable(),
  api_token: yup.string().required(),
  birthday: yup.string().nullable(),
  icon_image: yup.string().nullable(),
  identity: yup.number().required(),
  status: yup.number().required(),
  company_name: yup.string().nullable(),
  company_job: yup.string().nullable(),
  urls: yup.array(yup.string()).required(),
  mail_news_receive: yup.boolean().required(),
  social_capital_point: yup.number().nullable(),
  social_capital_ranking: yup.number().nullable(),
  age: yup.number().nullable(),
  tutorial_finished: yup.boolean().required(),
  is_company_info_open: yup.boolean().required(),
  has_new_stamps: yup.boolean().required(),
  has_new_feedback: yup.boolean().required(),
  industry_segment_parent: yup.string().required(),
  industry_segment_child: yup.string().required(),
  job_segment_parent: yup.string().required(),
  job_segment_child: yup.string().required(),
};
export const loginByEmailSchema = yup.object(baseSchema).required();

export const loginByFacebookSchema = yup.object({
  ...baseSchema,
  // 新規登録時のみプロパティが来るっぽい
  facebook_sign_up_token: yup.string().nullable().optional(),
});

// User entity にはあるが API から貰えない項目の型を補完している
// NOTE: 複数のクライアントを使ってUser entityを満たす実装にする場合は改修が必要
type Options = {
  email: string;
  profiee_url?: string;
  facebook_url?: string;
  is_profiee_url_open?: boolean;
  is_facebook_url_open?: boolean;
  tag_interest?: { id: number; title: string }[];
  links?: string[];
  job_news_recieve?: boolean;
  past_job_description?: string;
  skills?: { id: number; name: string }[];
  applying_for_change_email?: string | null;
};

export type LoginByEmailSchema = Readonly<InferType<typeof loginByEmailSchema>> & Options;
export type LoginByFacebookSchema = Readonly<InferType<typeof loginByFacebookSchema>> & Options;

export const editMySelfSchema = yup
  .object()
  .shape({
    icon_image: yup.string().optional(), // 未設定ならリクエストボディに含まれない
    last_name: yup.string().required(),
    first_name: yup.string().required(),
    email: yup.string().optional(),
    birthday: yup.string().required(),
    spreader_name: yup.string().nullable().default(null),
    password: yup.string().optional(), // 未入力ならリクエストボディに含まれない
    mail_news_receive: yup.boolean().required(),
    industry_segment_parent: yup.string().nullable().default(null),
    industry_segment_child: yup.string().nullable().default(null),
    job_segment_parent: yup.string().nullable().default(null),
    job_segment_child: yup.string().nullable().default(null),
    company_name: yup.string().nullable().default(null),
    company_job: yup.string().nullable().default(null),
    is_company_info_open: yup.boolean().required(),
    links: yup.array(yup.string().required()).required(),
    job_news_recieve: yup.boolean().required(),
    past_job_description: yup.string().nullable().default(null),
    skill_ids: yup.array(yup.number().required()).default([]).optional(),
  })
  .required();

export type EditMySelfSchema = yup.InferType<typeof editMySelfSchema> & {
  // User Entity にはあるが API から貰えない項目の型を補完している
  profiee_url?: string | null;
  facebook_url?: string | null;
  is_profiee_url_open?: boolean;
  is_facebook_url_open?: boolean;
  tag_interest_ids?: number[];
};

export type LoginByEmailResponse = {
  data: {
    id: number;
    spreader_name?: string | null;
    last_name: string;
    first_name: string;
    password?: string | null;
    api_token: string;
    birthday?: string | null;
    icon_image?: string | null;
    identity: number;
    status: number;
    company_name?: string | null;
    company_job?: string | null;
    urls: (string | undefined)[];
    mail_news_receive: boolean;
    social_capital_point?: number | null;
    social_capital_ranking?: number | null;
    age?: number | null;
    tutorial_finished: boolean;
    is_company_info_open: boolean;
    has_new_stamps: boolean;
    has_new_feedback: boolean;
    industry_segment_parent?: string | null;
    industry_segment_child?: string | null;
    job_segment_parent?: string | null;
    job_segment_child?: string | null;
  };
  message?: string;
};

export const chatRoomUserSchema = yup.object({
  id: yup.number().required(),
  last_name: yup.string().required(),
  first_name: yup.string().required(),
  icon_image: yup.string().nullable().default(null),
});

export type IChatRoomUserSchema = InferType<typeof chatRoomUserSchema>;

export const companySpreaderSchema = yup.object({
  id: yup.number().required(),
  spreader_name: yup.string().required(),
  icon_image: yup.string().nullable(),
  social_capital_point: yup.number().required(),
  social_capital_ranking: yup.number().nullable(),
  company_name: yup.string().nullable(),
  company_job: yup.string().nullable(),
  industry_segment_parent: yup.string().required(),
  industry_segment_child: yup.string().required(),
  job_segment_parent: yup.string().required(),
  job_segment_child: yup.string().required(),
  tag_interest: yup.array().of(yup.string()).required(),
  links: yup.array().of(yup.string()).required(),
});

type CompanySpreaderSchema = InferType<typeof companySpreaderSchema>;

export interface IUserFactory {
  buildEmptyUser(): User;
  buildUser(
    data:
      | CheckTokenUserSchema
      | LoginByEmailSchema
      | LoginByFacebookSchema
      | ConfirmEmailUserSchema
      | MySelfSchema
      | CompanySpreaderSchema
      | IUser,
  ): User;
  buildByChatRoom(data: IChatRoomUserSchema): User;
  buildSupportChatTitleUser(data: ISupportChatTitleUser): SupportChatTitleUser;
}

export class UserFactory implements IUserFactory {
  public buildEmptyUser = (identity = USER_IDENTITY.SPREADER): User => {
    return new User(
      null,
      '',
      '',
      '',
      '',
      null,
      '',
      null,
      '',
      identity,
      null,
      '',
      '',
      [''],
      true,
      0,
      null,
      null,
      '',
      '',
      true,
      true,
      false,
      true,
      false,
      false,
      [],
      '',
      '',
      '',
      '',
      [],
      false,
      '',
      [],
      null,
    );
  };

  public buildUser(
    data:
      | CheckTokenUserSchema
      | LoginByEmailSchema
      | LoginByFacebookSchema
      | ConfirmEmailUserSchema
      | MySelfSchema
      | IUser,
  ): User {
    const birthday = data.birthday ? new Date(data.birthday) : null;

    return new User(
      data.id,
      data.spreader_name || '',
      data.last_name,
      data.first_name,
      data.email || '',
      // passwordは空で埋める
      null,
      data.api_token,
      birthday,
      data.icon_image || '',
      data.identity,
      data.status,
      data.company_name || '',
      data.company_job || '',
      data.urls || [],
      data.mail_news_receive,
      data.social_capital_point || 0,
      data.social_capital_ranking || null,
      data.age || null,
      data.profiee_url || '',
      data.facebook_url || '',
      data.is_profiee_url_open || false,
      data.is_facebook_url_open || false,
      data.tutorial_finished || false,
      data.is_company_info_open || false,
      data.has_new_stamps || false,
      data.has_new_feedback || false,
      data.tag_interest || [],
      data.industry_segment_parent || '',
      data.industry_segment_child || '',
      data.job_segment_parent || '',
      data.job_segment_child || '',
      data.links || [],
      data.job_news_recieve || false,
      data.past_job_description || '',
      data.skills || [],
      data.applying_for_change_email || null,
    );
  }

  public buildByChatRoom(data: IChatRoomUserSchema): User {
    return new User(
      data.id,
      '',
      data.last_name,
      data.first_name,
      '',
      null,
      '',
      null,
      data.icon_image || '',
      USER_IDENTITY.SPREADER,
      null,
      '',
      '',
      [''],
      true,
      0,
      null,
      null,
      '',
      '',
      true,
      true,
      false,
      true,
      false,
      false,
      [],
      '',
      '',
      '',
      '',
      [],
      false,
      '',
      [],
      null,
    );
  }

  public buildSupportChatTitleUser(data: ISupportChatTitleUser): SupportChatTitleUser {
    return new SupportChatTitleUser(data.id, data.last_name, data.first_name, data.icon_image);
  }
}
