import styled from '@emotion/styled';
import { snackbarContext } from 'application/contexts/useSnackbar';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'ui/components/company/elements/Button/Button';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { ListTable } from 'ui/components/company/features/ListTable/ListTable';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { DeleteCompanyBillingInfo } from './DeleteCompanyBillingInfo';
import { useCompanyBillingInfo } from './useCompanyBillingInfo';
import { useEditCompanyBillingInfo } from './useEditCompanyBillingInfo';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: auto;
`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.5;
  color: var(--gray-dark-2);
`;

const Container = styled.div`
  padding: 30px 30px 30px 0;
`;

const PageTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 104px;
`;

/**
 * 企業側 請求先情報CRUD画面
 */
export const CompanyBillingInfo: React.FC = () => {
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const { showSnackbar, setShowSnackbar } = useContext(snackbarContext);
  const deleteModal = useModal();
  const [deleteTarget, setDeleteTarget] = useState<(typeof departments)[0] | undefined>();

  const { departments, refetch } = useCompanyBillingInfo();
  const { editInfo } = useEditCompanyBillingInfo();
  const data = departments.map((d, i) => ({ ...d, id: i, idStr: d.id }));

  const goDelete = (info: (typeof departments)[number]) => {
    setDeleteTarget(info);
    deleteModal.openModal();
  };

  const handleDeleteComplete = () => {
    setSnackBarMessage('請求先情報を削除しました');
    setShowSnackbar(true);
    refetch();
  };

  const handleMakeDefault = async (id: string) => {
    await editInfo({ id, input: { is_default: true } });
    setSnackBarMessage('デフォルト請求先を更新しました');
    setShowSnackbar(true);
    refetch();
  };

  return (
    <Wrapper>
      <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message={snackBarMessage} />
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <PageTitle>請求先情報一覧</PageTitle>
          <Link to="/company/billing-info/new?redirect-url=/company/billing-info/">
            <Button button_type="blue">新規請求先登録</Button>
          </Link>
        </div>

        <Description>
          見積書、請求書に記載される請求先情報を登録・削除できます。
          <br />
          メールアドレスは請求書送付先メールアドレスとして利用されます。
        </Description>

        <ListTable
          columns={[
            // { key: 'idStr', label: 'ID', width: '10%' },
            { key: 'zip', label: '郵便番号', width: '10%' },
            { key: 'prefecture', label: '都道府県', width: '10%' },
            { key: 'address1', label: '住所1', width: '10%' },
            { key: 'address2', label: '住所2', width: '10%' },
            { key: 'email', label: '請求書送付先メールアドレス', width: '10%' },
            { key: 'person_name', label: '担当者名', width: '10%' },
            { key: 'person_dept', label: '部署名', width: '10%' },
            { key: 'person_title', label: '役職', width: '10%' },
            {
              key: 'is_default',
              label: 'デフォルト',
              width: '10%',
              cellRenderer: (row) => (
                <div>
                  {row.is_default ? (
                    '✅'
                  ) : (
                    <Button
                      onClick={() => {
                        handleMakeDefault(row.idStr);
                      }}
                      button_type="transparent_blue"
                      padding="6px 16px"
                      fontSize="11px"
                      width="100%"
                    >
                      デフォルトにする
                    </Button>
                  )}
                </div>
              ),
            },
            {
              key: '',
              label: '',
              cellRenderer: (row) => (
                <Buttons>
                  <Button
                    button_type="pale_blue"
                    padding="6px 16px"
                    fontSize="11px"
                    width="100%"
                    onClick={() => goDelete({ ...row, id: row.idStr })}
                  >
                    削除
                  </Button>
                </Buttons>
              ),
            },
          ]}
          data={data}
          countPerPageOptions={[200]}
        />
      </Container>

      {deleteModal.createModal(
        <DeleteCompanyBillingInfo
          closeModal={deleteModal.closeModal}
          onComplete={handleDeleteComplete}
          info={deleteTarget}
        />,
      )}
    </Wrapper>
  );
};
