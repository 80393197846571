import { inject, injectable } from 'tsyringe';
import { type IMeetRequestFactory } from 'domain/entities/factories/MeetRequest';
import { type ICompanyMeetRequestRepository } from 'application/repositorySchemas/ICompanyMeetRequestRepository';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyMeetRequestMeetingAtClient } from 'interfaceAdapter/gatewaySchemas/ICompanyMeetRequestMeetingAtClient';
import { type ICompanyMeetRequestClient } from 'interfaceAdapter/gatewaySchemas/ICompanyMeetRequestClient';

@injectable()
export class CompanyMeetRequestRepository implements ICompanyMeetRequestRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IMeetRequestFactory')
    public readonly meetRequestFactory: IMeetRequestFactory,
    @inject('ICompanyMeetRequestClient')
    public readonly companyMeetRequestClient: ICompanyMeetRequestClient,
    @inject('ICompanyMeetRequestMeetingAtClient')
    public readonly companyMeetRequestMeetingAtClient: ICompanyMeetRequestMeetingAtClient,
  ) {}

  public async sendMeetRequest(
    toSpreaderId: number,
    topic: string,
    attender: string,
    projectId: number | null,
  ) {
    const { data, message } = await this.companyMeetRequestClient.post({
      token: this.companyUserToken.get() || '',
      to_spreader_id: toSpreaderId,
      topic,
      attender,
      project_id: projectId,
    });

    if (message) {
      throw new Error(message);
    }

    if (data !== true) {
      throw new Error('会いたいリクエストの送信に失敗しました');
    }
  }

  public async updateMeetingAt(meetRequestId: number, meetingAt: string | null) {
    const { data } = await this.companyMeetRequestMeetingAtClient.post({
      token: this.companyUserToken.get() || '',
      id: meetRequestId,
      meeting_at: meetingAt,
    });
    return {
      meetRequest: this.meetRequestFactory.buildMeetRequest(data),
    };
  }
}
