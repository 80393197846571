import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMeetRequest, MEET_REQUEST_STATUS } from 'domain/entities/MeetRequest/MeetRequest';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { companyAppContext } from 'application/contexts/useCompanyApp';

export interface MeetRequestItemProps {
  meetRequest: IMeetRequest;
}

export const useMeetRequestItem = (props: MeetRequestItemProps) => {
  const navigate = useNavigate();
  const addDateModal = useModal();
  const { setMeetRequestModalProps } = useContext(companyAppContext);

  // 面談日更新をUIに反映するための状態管理
  const [meetRequest, setMeetRequest] = useState<IMeetRequest>(props.meetRequest);

  const name = useMemo(() => {
    const useRealName =
      meetRequest.status === MEET_REQUEST_STATUS.CONNECTED ||
      !meetRequest.to_spreader.spreader_name;
    return useRealName
      ? `${meetRequest.to_spreader.last_name}${meetRequest.to_spreader.first_name}`
      : meetRequest.to_spreader.spreader_name;
  }, [meetRequest]);

  const openMeetRequestModal = useCallback(() => {
    setMeetRequestModalProps({
      userId: meetRequest.to_spreader.id,
      project: meetRequest.project,
    });
  }, [meetRequest]);

  const openUserDetail = useCallback((userId: number) => {
    const qs = new URLSearchParams(window.location.search);
    qs.set('user', `${userId}`);
    navigate(`${window.location.pathname}?${qs.toString()}`);
  }, []);

  const onClickMessage = useCallback((roomId: string) => {
    navigate(`/company/main/chat/${roomId}/?type=mr`);
  }, []);

  const onSaveMeetingAt = useCallback((newMeetRequest: IMeetRequest) => {
    addDateModal.closeModal();
    setMeetRequest(newMeetRequest);
  }, []);

  useEffect(() => {
    setMeetRequest(props.meetRequest);
  }, [props.meetRequest]);

  return {
    meetRequest,
    addDateModal,
    name,
    openMeetRequestModal,
    openUserDetail,
    onClickMessage,
    onSaveMeetingAt,
  };
};
