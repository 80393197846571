import * as yup from 'yup';
import * as DateUtils from 'utils/date';
import { CHAT_LABEL, CHAT_TYPE, ChatRoom } from 'domain/entities/ChatRoom/ChatRoom';
import { IUser } from 'domain/entities/User/User';
import { UserFactory } from 'domain/entities/factories/User';
import {
  CompanyUserFactory,
  partialCompanyUserSchema,
} from 'domain/entities/factories/CompanyUser';
import { BuildProjectInput, ProjectFactory } from 'domain/entities/factories/Project';

export const chatRoomSchema = yup.object({
  spreader: yup.object().required(), // User
  from_company_user: partialCompanyUserSchema.default(undefined),
  room_id: yup.string().required(),
  connected_at: yup.string().nullable().optional(),
  my_join: yup.boolean().required(),
  last_message_sent_at: yup.date().required(),
  last_message_text: yup.string().required(),
  passed_days: yup.number().nullable().required(),
  chat_type: yup.string().oneOf(Object.values(CHAT_TYPE)).required(),
  introduced_user: yup.object().required(), // User
  project: yup.object().required(),
  project_spreader_id: yup.number().optional(),
  assigned_at: yup.date().required(),
  meetup_date_at: yup.date().default(null).nullable().optional(),
  label: yup.string().oneOf(Object.values(CHAT_LABEL)).required(),
});

export type IChatRoomSchema = yup.InferType<typeof chatRoomSchema>;

export const meetRequestChatRoomSchema = yup.object({
  spreader: yup.object().required(), // User
  from_company_user: partialCompanyUserSchema.default(undefined),
  room_id: yup.string().required(),
  connected_at: yup.string().optional(),
  my_join: yup.boolean().required(),
  last_message_sent_at: yup.date().required(),
  last_message_text: yup.string().required(),
  passed_days: yup.number().required(),
});
type IBuildByMeetRequestChatRoomInput = Readonly<yup.InferType<typeof meetRequestChatRoomSchema>>;

export const introductionChatRoomSchema = yup.object({
  spreader: yup.object().required(), // User
  introduced_user: yup.object().required(), // User
  project: yup.object().required(), // Project projectSchemaと比較するといくつかの必須のプロパティが来ないので object() で定義
  project_spreader_id: yup.number().required(),
  room_id: yup.string().required(),
  meetup_date_at: yup.date().default(null).nullable().optional(),
  assigned_at: yup.date().required(),
  my_join: yup.boolean().required(),
  last_message_sent_at: yup.date().required(),
  last_message_text: yup.string().required(),
  passed_days: yup.number().required(),
});
type IBuildByIntroductionChatRoomInput = Readonly<yup.InferType<typeof introductionChatRoomSchema>>;

export interface IChatRoomFactory {
  build(data: IChatRoomSchema, chatType?: 'cast' | 'mr'): ChatRoom;
  buildByMeetRequest(data: IBuildByMeetRequestChatRoomInput): ChatRoom;
  buildByIntroduction(data: IBuildByIntroductionChatRoomInput): ChatRoom;
}

export class ChatRoomFactory implements IChatRoomFactory {
  public build = (data: IChatRoomSchema, chatType: 'cast' | 'mr' = 'cast') => {
    const spreader = data.spreader ? new UserFactory().buildUser(data.spreader as IUser) : null;
    const introduced_user = data.introduced_user
      ? new UserFactory().buildUser(data.introduced_user as IUser)
      : null;
    const assigned_at = data.assigned_at ? new Date(data.assigned_at) : null;
    const company_user = data.from_company_user
      ? new CompanyUserFactory().buildByPartial(data.from_company_user)
      : null;
    const project = data.project
      ? new ProjectFactory().buildProject(data.project as BuildProjectInput)
      : null;
    const meetup_date_at = data.meetup_date_at ? new Date(data.meetup_date_at) : null;
    const last_message_sent_at = data.last_message_sent_at
      ? new Date(data.last_message_sent_at)
      : null;
    const passed_time = last_message_sent_at ? DateUtils.getDisplayDate(last_message_sent_at) : '';

    return new ChatRoom(
      data.room_id,
      data.chat_type,
      false,
      data.passed_days,
      passed_time,
      data.label,
      data.my_join,
      spreader,
      introduced_user,
      assigned_at,
      company_user,
      project,
      meetup_date_at,
      data.last_message_text,
      last_message_sent_at,
      chatType,
      data.project_spreader_id,
    );
  };

  public buildByMeetRequest(data: IBuildByMeetRequestChatRoomInput): ChatRoom {
    const chatType = 'mr';
    const companyUser = data.from_company_user
      ? new CompanyUserFactory().buildByPartial(data.from_company_user)
      : null;
    const lastMessageSentAt = data.last_message_sent_at
      ? new Date(data.last_message_sent_at)
      : null;
    const passedTime = lastMessageSentAt ? DateUtils.getTimeDiff(lastMessageSentAt) : '';

    return new ChatRoom(
      data.room_id,
      chatType,
      false,
      data.passed_days,
      passedTime,
      CHAT_LABEL.MEET_REQUEST,
      data.my_join,
      null,
      null,
      null,
      companyUser,
      null,
      null,
      data.last_message_text,
      lastMessageSentAt,
      chatType,
    );
  }

  public buildByIntroduction(data: IBuildByIntroductionChatRoomInput): ChatRoom {
    const chatType = 'cast';
    const spreader = data.spreader ? new UserFactory().buildUser(data.spreader as IUser) : null;
    const introduced_user = data.introduced_user
      ? new UserFactory().buildUser(data.introduced_user as IUser)
      : null;
    const assigned_at = data.assigned_at ? new Date(data.assigned_at) : null;
    const project = data.project
      ? new ProjectFactory().buildProject(data.project as BuildProjectInput)
      : null;
    const meetup_date_at = data.meetup_date_at ? new Date(data.meetup_date_at) : null;
    const last_message_sent_at = data.last_message_sent_at
      ? new Date(data.last_message_sent_at)
      : null;
    const passed_time = last_message_sent_at ? DateUtils.getTimeDiff(last_message_sent_at) : '';

    return new ChatRoom(
      data.room_id,
      chatType,
      false,
      data.passed_days,
      passed_time,
      CHAT_LABEL.CASTING,
      data.my_join,
      spreader,
      introduced_user,
      assigned_at,
      null,
      project,
      meetup_date_at,
      data.last_message_text,
      last_message_sent_at,
      chatType,
      data.project_spreader_id,
    );
  }
}
