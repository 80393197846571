import styled from '@emotion/styled';
import { SimpleHeader } from 'ui/components/company/panes/Header/SimpleHeader';
import { InviteForm } from 'ui/components/company/patterns/InviteForm/InviteForm';
import { mqUser } from 'utils/responsive';

const Body = styled.div`
  width: 100%;
  height: calc(100vh - 82px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 82px);
  }

  margin-inline: auto;
  padding: 20px;
  background-color: var(--primary);
  overflow-y: auto;
`;

const Title = styled.div`
  font-size: var(--font-xxxxx-large);
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const Container = styled.div`
  max-width: 460px;
  margin: 50px auto;
`;

export const Invite: React.FC = () => {
  return (
    <>
      <SimpleHeader />
      <Body>
        <Title>ユーザーを招待する</Title>
        <Container>
          <InviteForm />
        </Container>
      </Body>
    </>
  );
};
