import styled from '@emotion/styled';
import { useHeader } from './useHeader';
import { UserIcon } from '../../elements/UserIcon/UserIcon';
import MenuIcon from 'ui/images/company/icons/menu.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  height: 100%;
  padding-inline: 16px;
  background: var(--spr-white);
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 37px;
    height: 37px;
  }
  span {
    font-size: var(--font-small);
    line-height: var(--font-small);
    font-weight: var(--font-bold);
  }
`;

const Company = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Name = styled.span`
  color: #333;
  font-weight: 700;
  font-size: 18px;
`;

const User = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

// メニュー表示時にスクロールできないようにする
const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  background-color: transparent;
  overscroll-behavior: contain;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  touch-action: none;
  z-index: var(--tooltip);

  &:before {
    content: '';
    width: 1px;
    height: calc(100vh + 1px);
    @supports (height: 100svh) {
      height: calc(100svh + 1px);
    }
    display: flex;
  }
`;

const Popover = styled.div`
  display: inline-block;
  position: fixed;
  height: auto;
  z-index: var(--tooltip);
  pointer-events: auto;
  background-color: var(--spr-white);
  border-radius: 4px;
  top: var(--height-company-pc-header);
  right: 20px;
  transform-origin: right top;
  margin-right: 8px;
  box-shadow: 0 0 4px #a9a9a9;
  visibility: ${(props: { isHidden: boolean }) => (props.isHidden ? 'hidden' : 'visible')};
  opacity: ${(props: { isHidden: boolean }) => (props.isHidden ? 0 : 1)};
  transition: opacity 0.1s ease-in;
`;

const Item = styled.div`
  padding: 5px 10px;
  border-bottom: 1px solid var(--gray-light-2);
  font-size: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const Header: React.FC = () => {
  const { user, popoverOpen, setPopoverOpen, menuItems, onItemClick, setIsSideOpen } = useHeader();
  if (!user.company) return null;

  return (
    <Wrapper>
      <Menu
        role="button"
        aria-label="サイドメニューの開閉"
        onClick={() => setIsSideOpen((v) => !v)}
      >
        <img src={MenuIcon} alt="メニューアイコン" />
        <span>MENU</span>
      </Menu>
      <Company>
        <UserIcon src={user.company.icon_image} size={40} />
        <Name>{user.company.name}</Name>
      </Company>
      <User onClick={() => setPopoverOpen(!popoverOpen)}>
        <UserIcon src={user.icon_image} size={32} />
      </User>
      {popoverOpen && <Overlay onClick={() => setPopoverOpen(false)} />}
      <Popover isHidden={!popoverOpen}>
        {menuItems.map((item) => (
          <Item key={item.url} onClick={() => onItemClick(item)}>
            {item.text}
          </Item>
        ))}
      </Popover>
    </Wrapper>
  );
};
