import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IMessageFactory } from 'domain/entities/factories/Message';
import {
  buildResponse,
  HttpBadRequest,
  HttpForbidden,
  HttpNotFound,
} from 'domain/types/httpResponse';
import { type ISupportChatMessageQuery } from 'application/querySchemas/ISupportChatMessageQuery';
import { type ISupportChatMessageClient } from 'interfaceAdapter/gatewaySchemas/ISupportChatMessage';

@injectable()
export class SupportChatMessageQuery implements ISupportChatMessageQuery {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ISupportChatMessageClient')
    public readonly supportChatMessageClient: ISupportChatMessageClient,
    @inject('IMessageFactory')
    public readonly messageFactory: IMessageFactory,
  ) {}

  public async getMessages(roomId: string) {
    const { data, status, message } = await this.supportChatMessageClient.get({
      token: this.companyUserToken.get() || '',
      roomId,
    });
    const throwable = buildResponse(status, message);
    if (
      throwable instanceof HttpNotFound ||
      throwable instanceof HttpBadRequest ||
      throwable instanceof HttpForbidden
    ) {
      throw throwable;
    }
    return data.messages.map((message) => this.messageFactory.build(message));
  }
}
