import { diContainerContext } from 'application/contexts/useDiContainer';
import { ICompanySupportChat } from 'domain/valueObjects/companySupportChat/CompanySupportChat';
import { CompanyChatRoomRepository } from 'interfaceAdapter/repositories/CompanyChatRoom';
import { CompanyChatRoomQuery } from 'interfaceAdapter/queries/CompanyChatRoom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { companyUserContext } from 'application/contexts/useCompanyUser';

export const useSupportRoomList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const diContainer = useContext(diContainerContext);
  const companyChatRoomRepository = diContainer.resolve(CompanyChatRoomRepository);
  const companyChatRoomQuery = diContainer.resolve(CompanyChatRoomQuery);
  const [rooms, setRooms] = useState<ICompanySupportChat[]>([]);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [room, setRoom] = useState<ICompanySupportChat | null>(null);
  const [query, setQuery] = useState<string>('');
  const { user } = useContext(companyUserContext);

  const fetchRooms = useCallback(async () => {
    if (!query) {
      setRooms([]);
      return;
    }
    const rooms = await companyChatRoomQuery.getSupportChat(query);
    setRooms(rooms);
  }, [query]);

  const openRoom = useCallback((roomId: string) => {
    setRoomId(roomId);
    navigate(`/company/main/chat/support/${roomId}/`);
  }, []);

  const archiveRoom = useCallback(async (roomId: string) => {
    companyChatRoomRepository.archiveCastingChatRoom(roomId);
  }, []);

  const onArchiveRoom = useCallback((roomId: string) => {
    setRooms((rooms) => rooms.filter((room) => room.room_id !== roomId));
  }, []);

  useEffect(() => {
    if (params.roomId) {
      setRoomId(params.roomId);
    }

    const room = rooms.find((room) => room.room_id === params.roomId);
    setRoom(room || null);
  }, [params]);

  useEffect(() => {
    if (!user.isAdmin) {
      return;
    }
    if (query) {
      fetchRooms();
    } else {
      setRooms([]);
    }
  }, [query]);

  return {
    room,
    roomId,
    rooms,
    query,
    setQuery,
    openRoom,
    archiveRoom,
    onArchiveRoom,
  };
};
