import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { FormPasswordInput } from 'ui/components/company/elements/FormInput/FormPasswordInput';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { useCompanyUserSignUp } from './useCompanyUserSignUp';

const Wrapper = styled.div`
  padding: 40px 30px;
  border-radius: 12px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: var(--font-size--medium);
`;

const Title = styled.div`
  font-size: var(--font-size--xx-large);
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const Text = styled.div`
  margin: 0;
  font-size: var(--font-size--medium);
  line-height: 1.5;

  a {
    color: blue;
    text-decoration: underline;
  }

  span {
    background-color: var(--gray-light-2);
    padding: 2px 4px;
    margin: 0 2px;
    border-radius: 2px;
  }
`;

const PreviewEmail = styled.div`
  font-size: var(--font-size--medium);
  font-weight: var(--font-weight--bolder);
`;

export const CompanyUserSignUpForm: React.FC = () => {
  const { email, control, complete, expired, loading, errors, onSubmit } = useCompanyUserSignUp();

  if (expired) {
    return (
      <Wrapper>
        <Title>メールアドレス認証に失敗しました</Title>
        <Text>
          メールアドレス認証有効期限切れのため、
          <Link to="/company/register/email/">新規登録</Link>
          をお願いいたします。
        </Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {complete ? (
        <>
          <Title>メールアドレス認証完了</Title>
          <Text>ユーザー情報の登録フォームへ移動します。</Text>
        </>
      ) : (
        <>
          <Title>ログインパスワード登録</Title>
          <PreviewEmail>{email}</PreviewEmail>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <div>
                <FormPasswordInput {...field} hasError={!!errors.password?.message} />
                {errors.password?.message && <FormError message={errors.password.message} />}
              </div>
            )}
          />
          <Text>
            パスワードは英字の大文字、英字の小文字、記号<span>-_.@</span>、
            数字を組み合わせて9文字以上で設定してください
          </Text>
          <ActionButton
            disabled={loading}
            onClick={onSubmit}
            bgColor="secondary-dark"
            style={{ padding: '22px 70px' }}
          >
            設定する
          </ActionButton>
        </>
      )}
    </Wrapper>
  );
};
