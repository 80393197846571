import { useContext, useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { COMPANY_STATUS } from 'domain/entities/Company/Company';
import { companyUserFormSchema } from 'domain/entities/factories/CompanyUser';
import { CompanySearchResult } from 'domain/entities/CompanySearchResult/CompanySearchResult';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserCheckToken } from 'application/usecases/CompanyUserCheckToken';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { toHankaku } from 'utils/string';

type Form = yup.InferType<typeof companyUserFormSchema>;

export const useCompanyUserForm = () => {
  const [requesting, setRequesting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const companyUserCheckToken = diContainer.resolve(CompanyUserCheckToken);
  const navigate = useNavigate();
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(companyUserFormSchema),
    mode: 'onChange',
  });

  const nextPath = useMemo(() => {
    // 招待の場合は企業情報の入力と内容の確認はスキップ
    return isInvited ? '/company/registration/complete/' : '/company/register/company/profile/';
  }, [isInvited]);

  const registerFunc = useMemo(() => {
    return isInvited
      ? companyUserRepository.registerInvitedUserProfile
      : companyUserRepository.registerProfile;
  }, [isInvited]);

  const onSubmit = handleSubmit(async (data) => {
    setRequesting(true);
    try {
      data.company_name = toHankaku(data.company_name);
      await registerFunc({
        last_name: data.last_name,
        first_name: data.first_name,
        icon_image: data.icon_image === '' ? null : data.icon_image,
        department: data.department,
        job: data.job === '' ? null : data.job,
        phone_number: data.phone_number,
        company_name: data.company_name,
        corporate_number: data.corporate_number,
        zipcode: data.zipcode,
        address: data.address,
        referral_code: data.referral_code === '' ? null : data.referral_code,
      });
      setShowSnackbar(true);
      setTimeout(() => navigate(nextPath), 1500);
    } finally {
      setRequesting(false);
    }
  });

  const onCompanySelect = (company: CompanySearchResult) => {
    setValue('company_name', company.name);
    setValue('corporate_number', company.number);
    setValue('zipcode', company.zip_code);
    setValue('address', company.address);
  };

  useEffect(() => {
    const initialFetch = async () => {
      const result = await companyUserCheckToken.execute(true);
      if (result !== false) {
        setValue('last_name', result.last_name);
        setValue('first_name', result.first_name);
        setValue('icon_image', result?.icon_image ?? '');
        setValue('company_name', result?.company?.name ?? '');
        setValue('corporate_number', result?.company?.corporate_number ?? '');
        setValue('zipcode', result?.company?.zipcode ?? '');
        setValue('address', result?.company?.address ?? '');
        setValue('department', result?.department ?? '');
        setValue('job', result.job ?? '');
        setValue('phone_number', result?.phone_number ?? '');
        setValue('referral_code', result?.referral_code ?? '');

        setIsInvited(result.company && result.company.status === COMPANY_STATUS.ACTIVE); // 企業ステータスが Active である = 初回のユーザーの登録が完了しているので招待、とみなす
      }
    };
    initialFetch();
  }, []);

  return {
    requesting,
    control,
    errors,
    showSnackbar,
    isInvited,
    nextPath,
    setShowSnackbar,
    watch,
    setValue,
    onSubmit,
    onCompanySelect,
  };
};
