import styled from '@emotion/styled';
import { ListTableColumn } from 'ui/components/company/features/ListTable/useListTable';
import { ListTable } from 'ui/components/company/features/ListTable/ListTable';
import { Button } from 'ui/components/company/elements/Button/Button';
import { DraftProjectListTableRow, useDraftProjectListTable } from './useDraftProjectListTable';
import { DeleteDraftProjectConfirm } from 'ui/components/company/screens/modals/DeleteDraftProjectConfirm/DeleteDraftProjectConfirm';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.div`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  padding-left: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 104px;
  margin-block: 8px;
`;

const DeleteButton = styled.button`
  max-width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  text-align: center;
  font-size: 11px;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  background-color: var(--spr-warn-paler);
  color: var(--spr-warn);
  padding: 6px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

/**
 * 下書きセッション一覧テーブル
 */
export const DraftProjectListTable: React.FC = () => {
  const {
    draftProjects,
    gotoEditProjectPage,
    showCreateProjectButton,
    gotoPrepareProjectPage,
    deleteConfirmModal,
    showSnackbar,
    setShowSnackbar,
    onCancelDelete,
    onClickDeleteButton,
    deleteProject,
  } = useDraftProjectListTable();

  const TABLE_COLUMNS: ListTableColumn<DraftProjectListTableRow>[] = [
    {
      key: 'title',
      label: 'セッション名',
      width: '78%',
    },
    {
      key: 'create_name',
      label: '作成',
      width: '10%',
    },
    {
      key: '',
      label: '',
      cellRenderer: (row: DraftProjectListTableRow) => (
        <Buttons>
          <Button
            button_type="blue"
            padding="6px 16px"
            fontSize="11px"
            onClick={() => gotoEditProjectPage(row.id)}
          >
            編集する
          </Button>
          <DeleteButton onClick={() => onClickDeleteButton(row.id)}>削除する</DeleteButton>
        </Buttons>
      ),
    },
  ];

  return (
    <>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={'下書きセッションを削除しました'}
      />
      <Header>
        {showCreateProjectButton && (
          <Button button_type="blue" padding="10px 16px" onClick={gotoPrepareProjectPage}>
            新規セッション作成
          </Button>
        )}
      </Header>

      <Title>下書き一覧</Title>
      <ListTable columns={TABLE_COLUMNS} data={draftProjects} countPerPageOptions={[5, 10, 50]} />

      {deleteConfirmModal.createModal(
        <DeleteDraftProjectConfirm
          closeModal={() => onCancelDelete()}
          onExecute={() => deleteProject()}
        />,
        { onClose: () => onCancelDelete() },
      )}
    </>
  );
};
