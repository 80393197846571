import { inject, injectable } from 'tsyringe';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IUploadFileRepository } from 'application/repositorySchemas/IUploadFileRepository';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type IUploadFileClient } from 'interfaceAdapter/gatewaySchemas/IUploadFileClient';

@injectable()
export class UploadFileRepository implements IUploadFileRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('IUploadFileClient')
    public readonly uploadFileClient: IUploadFileClient,
  ) {}

  public upload = async (
    file: File,
    fileName: string,
    directory: 'profile' | 'company' | 'project',
    fileCategory: 'user' | 'company',
    width: number,
    height: number,
    urlId?: string | null,
  ): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', fileName);
    formData.append('file_directory', directory);
    formData.append('file_category', fileCategory);
    formData.append('width', `${width}`);
    formData.append('height', `${height}`);
    // @ts-expect-error formDataはnullを許容しないが、APIはnullを受け付ける
    formData.append('url_id', urlId);

    const { data, message } = await this.uploadFileClient.post({
      token:
        fileCategory === 'user' ? this.userToken.get() ?? '' : this.companyUserToken.get() ?? '',
      category: fileCategory,
      formData,
    });

    if (message) {
      throw new Error(message);
    }

    return data.url;
  };
}
