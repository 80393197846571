import styled from '@emotion/styled';
import { Refresh } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { ICompanyMoneyForwardDepartment } from 'domain/valueObjects/CompanyMoneyForward/CompanyMoneyForwardDepartment';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Select } from '../../elements/Select/Select';

const SubTitle = styled.div`
  margin-bottom: 20px;
  font-weight: semi-bold;
  font-size: var(--font-large);
  line-height: 1.2;
  text-align: center;
`;
const TipsText = styled.span`
  font-size: var(--font-default);
  color: var(--gray-dark-2);
`;

const BillingInfoButtons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
  margin-top: 8px;
`;

const Button = styled.button<{ padding?: string; bgColor: string; textColor?: string }>`
  border-radius: 4px;
  padding: ${(props) => props.padding || '12px 30px'};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  border: none;
  white-space: nowrap;
  cursor: pointer;
`;

export const CompanyMfDepartmentSelect = (props: {
  selectedDepartmentId: string | undefined;
  refetchDepartments: () => void;
  loading: boolean;
  moneyForwardDepartments: ICompanyMoneyForwardDepartment[] | null;
  onSelect: (id: string) => void;
  title?: string;
}) => {
  const { control } = useForm();

  if (!props.selectedDepartmentId) {
    return null;
  }

  if (!props.moneyForwardDepartments) {
    return null;
  }

  return (
    <>
      <SubTitle>{props.title ?? 'お見積書・請求書宛先'}</SubTitle>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.2rem',
          marginBottom: '0.5rem',
        }}
      >
        <TipsText>
          ＊「郵便番号、住所、部署、役職、ご担当者お名前、請求書送付先メールアドレス」の順番で表示されています
        </TipsText>
        <TipsText>
          ＊
          宛先にメールアドレスが含まれていない場合はログインしているアカウントのメールアドレスに請求書が送付されます
        </TipsText>
      </div>

      <Controller
        name="ticketAmount"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            value={props.selectedDepartmentId}
            options={props.moneyForwardDepartments?.map((d) => ({
              value: d.id,
              label: ` ${d.zip || ''} ${d.prefecture || ''} ${d.address1 || ''} ${
                d.address2 || ''
              } ${d.person_dept || ''} ${d.person_title || ''} ${d.person_name || ''} ${
                d.email || ''
              }`,
            }))}
            onChange={(i) => {
              props.onSelect(i.target.value);
            }}
          />
        )}
      />

      <BillingInfoButtons>
        <Link target="_blank" to="/company/billing-info/new">
          <Button
            bgColor="var(--gray-light-2)"
            padding="6px 16px"
            style={{
              maxWidth: 'max-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '4px',
            }}
            // onClick={handleAddDepartmentButtonPressed}
          >
            宛先を追加する
            <LaunchIcon />
          </Button>
        </Link>

        <Button
          bgColor="var(--gray-light-2)"
          padding="6px 16px"
          disabled={props.loading}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
          }}
          onClick={props.refetchDepartments}
        >
          {props.loading ? '更新中...' : '一覧を更新'}
          <Refresh />
        </Button>
      </BillingInfoButtons>
    </>
  );
};
