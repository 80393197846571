import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import yup from 'utils/yup';
import { useNavigate } from 'react-router-dom';
import { CompanyEditRepository } from 'interfaceAdapter/repositories/CompanyEdit';
import { CompanyOgp } from 'domain/valueObjects/Ogp/CompanyOgp';
import { MyCompanyQuery } from 'interfaceAdapter/queries/MyCompany';
import { companyUserContext } from 'application/contexts/useCompanyUser';

const schema = yup
  .object()
  .shape({
    name: yup.string().default('').optional(),
    copy: yup.string().default('').optional(),
    address: yup.string().default('').required(),
    zipcode: yup.string().default('').required(),
    url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .required(),
    president_name: yup.string().default('').optional(),
    capital: yup
      .number()
      .transform((convertedValue, value) => (value === '' ? null : convertedValue))
      .optional()
      .nullable()
      .typeError('正しく入力してください'),
    employee: yup
      .number()
      .transform((convertedValue, value) => (value === '' ? null : convertedValue))
      .optional()
      .nullable()
      .typeError('正しく入力してください'),
    about_company: yup.string().default('').optional(),
    icon_image: yup.string().default('').optional(),
    logo_display_enable: yup.boolean().default(true).required(),
    herp_webhook_url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .optional(),
    pics: yup.array(yup.string().required()).default([]).required(),
    urls: yup
      .array(
        yup
          .string()
          .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
          .default(''),
      )
      .default(['']),
    slack_webhook_url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .optional(),
  })
  .required();

type CompanyForm = yup.InferType<typeof schema>;

export const useCompanyEditForm = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const { user } = useContext(companyUserContext);
  const companyEditRepository = diContainer.resolve(CompanyEditRepository);
  const myCompanyQuery = diContainer.resolve(MyCompanyQuery);
  const [requesting, setRequesting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {
    watch,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: schema.cast({}),
  });

  const onImageUpload = useCallback(async (fileUrl: string) => {
    setValue('icon_image', fileUrl);
  }, []);

  const onPicsUpload = useCallback(async (fileUrl: string, index: number) => {
    const pics = getValues('pics');

    if (pics[index]) {
      pics[index] = fileUrl;
    } else {
      pics.push(fileUrl);
    }

    setValue('pics', pics);
  }, []);

  const removeEmptyUrls = () => {
    const urls = getValues('urls');
    const filteredUrls = urls.filter((url) => url !== '');
    setValue('urls', filteredUrls);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      setRequesting(true);
      const urls = getValues('urls');
      const filteredUrls = urls.filter((url) => url !== '');

      await companyEditRepository.edit({
        ...data,
        urls: filteredUrls,
        capital: data.capital ? data.capital : null,
        employee: data.employee ? data.employee : null,
        copy: data.copy ? data.copy : null,
        president_name: data.president_name ? data.president_name : null,
        herp_webhook_url: data.herp_webhook_url ? data.herp_webhook_url : null,
        slack_webhook_url: data.slack_webhook_url ? data.slack_webhook_url : null,
      });
      setSnackbarMessage('企業情報の変更を完了しました');
      setShowSnackbar(true);
      setTimeout(() => navigate('/company/main/'), 1500);
    } finally {
      setRequesting(false);
    }
  });

  const testSlackWebhook = async () => {
    try {
      setRequesting(true);
      const url = getValues('slack_webhook_url');
      const res = await companyEditRepository.testSlackWebhook(url);
      setSnackbarMessage(
        res
          ? '送信しました。届いていない場合はURLが正しいかご確認下さい'
          : '失敗しました。時間を置いて再度お試し下さい',
      );
      setShowSnackbar(true);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const company = await myCompanyQuery.fetch();

      setValue('name', company.name);
      setValue('copy', company.copy ?? '');
      setValue('address', company.address);
      setValue('zipcode', company.zipcode ?? '');
      setValue('url', company.url);
      setValue('president_name', company.president_name ?? '');
      company.capital && setValue('capital', company.capital);
      company.employee && setValue('employee', company.employee);
      setValue('about_company', company.about_company ?? '');
      setValue('icon_image', company.icon_image ?? '');
      setValue('logo_display_enable', company.logo_display_enable ?? true);
      setValue('herp_webhook_url', company.herp_webhook_url ?? '');
      setValue('slack_webhook_url', company.slack_webhook_url ?? '');
      setValue('pics', company.pics ?? []);

      const urlsArr: string[] = [];
      if (company.url_ogps && company.url_ogps.length >= 1) {
        company.url_ogps.forEach((data: CompanyOgp) => {
          urlsArr.push(data.url);
        });
      }
      setValue('urls', urlsArr);
    };
    fetchCompany();
  }, []);

  return {
    user,
    showSnackbar,
    control,
    errors,
    requesting,
    snackbarMessage,
    onImageUpload,
    onPicsUpload,
    onSubmit,
    setValue,
    setShowSnackbar,
    removeEmptyUrls,
    watch,
    testSlackWebhook,
  };
};
