import { useCallback, useContext, useEffect, useState } from 'react';
import { ImageUploaderProps } from './ImageUploader';
import { useModal } from 'ui/leo/components/user/screens/modals/useModal';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UploadFileRepository } from 'interfaceAdapter/repositories/UploadFile';
import { useLocation } from 'react-router-dom';

export const useImageUploader = ({
  imagePath,
  directory,
  fileName,
  fileCategory,
  width = 180,
  height = 180,
  urlId,
  onComplete,
}: ImageUploaderProps) => {
  const location = useLocation();
  const diContainer = useContext(diContainerContext);
  const uploadFileRepository = diContainer.resolve(UploadFileRepository);
  const reader = new FileReader();
  const { createModal, openModal, closeModal } = useModal();
  const [uploading, setUploading] = useState(false);
  const [newImagePath, setNewImagePath] = useState<string | null>(null);
  const [imgBase64, setImgBase64] = useState<string>('');

  const onInput = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgBase64(reader.result as string);
      openModal();
    };
    // 空にしないと同じファイルを選択したときにonInputが発火しない
    e.target.value = '';
  }, []);

  const onClip = useCallback(
    async (blob: Blob) => {
      setUploading(true);
      const file = new File([blob], 'sample.png');
      try {
        const fileUrl = await uploadFileRepository.upload(
          file,
          fileName,
          directory,
          fileCategory,
          width,
          height,
          urlId,
        );
        setNewImagePath(`${fileUrl}?hash=${Math.floor(Math.random() * 10000)}`);
        onComplete(fileUrl);
        closeModal();
      } finally {
        setUploading(false);
      }
    },
    [directory, fileName, fileCategory, urlId, location.pathname],
  );

  useEffect(() => {
    if (!imagePath) return;
    setNewImagePath(`${imagePath}?hash=${Math.floor(Math.random() * 10000)}`);
  }, [imagePath]);

  return {
    newImagePath,
    imgBase64,
    uploading,
    onInput,
    onClip,
    createModal,
    closeModal,
  };
};
