import styled from '@emotion/styled';
import { Button } from 'ui/components/company/elements/Button/Button';
import { Tabs } from 'ui/components/company/elements/Tabs/Tabs';
import {
  TAB_OPTION_VALUES,
  UserListTab,
  UserListTableRow,
  useUserListTable,
} from './useUserListTable';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { ListTable } from '../../features/ListTable/ListTable';
import { ListTableColumn } from '../../features/ListTable/useListTable';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const WarningText = styled.p`
  color: var(--spr-warn);
  margin: 0;
`;

const ActionButtons = styled.div`
  display: flex;
  column-gap: 8px;
  row-gap: 4px;
  flex-wrap: wrap;
  white-space: nowrap;
  margin-right: 16px;
  justify-content: flex-end;
`;

/**
 * 企業ユーザー 一覧テーブル
 */
export const UserListTable: React.FC = () => {
  const {
    users,
    requesting,
    successMessage,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    showCreateUserButton,
    showTabs,
    gotoCreateUserPage,
    gotoEditUserPage,
    tabOptions,
    selectedTab,
    setSelectedTab,
    resendRegisterEmail,
    disableUser,
    deletePreUser,
  } = useUserListTable();

  const TABLE_COLUMNS: ListTableColumn<UserListTableRow>[] = [
    {
      key: 'name',
      label: '名前',
      width: '40%',
      onCellClick:
        selectedTab === TAB_OPTION_VALUES.ACTIVE
          ? (row: UserListTableRow) => gotoEditUserPage(row.id)
          : undefined,
    },
    {
      key: 'email',
      label: 'メールアドレス',
    },
    {
      key: 'role',
      label: 'ロール',
    },
    {
      key: 'department',
      label: '部署',
    },
    {
      key: 'job',
      label: '役職',
    },
    {
      key: '',
      label: '',
      cellRenderer: (row: UserListTableRow) => (
        <>
          {selectedTab === TAB_OPTION_VALUES.ACTIVE && (
            <ActionButtons>
              <Button
                button_type="transparent_blue"
                width="88px"
                padding="10px 16px"
                onClick={() => gotoEditUserPage(row.id)}
              >
                編集する
              </Button>
              <Button
                button_type="red"
                padding="10px 16px"
                disabled={requesting}
                onClick={() => disableUser(row.id)}
              >
                無効にする
              </Button>
            </ActionButtons>
          )}
          {selectedTab === TAB_OPTION_VALUES.PROVISIONAL && (
            <ActionButtons>
              <Button
                button_type="transparent_blue"
                width="185px"
                padding="10px 16px"
                disabled={requesting}
                onClick={() => resendRegisterEmail(row.id)}
              >
                本登録メールを再送する
              </Button>
              <Button
                button_type="red"
                padding="10px 16px"
                disabled={requesting}
                onClick={() => deletePreUser(row.id)}
              >
                削除する
              </Button>
            </ActionButtons>
          )}
        </>
      ),
    },
  ];

  const visibleColumns =
    selectedTab === TAB_OPTION_VALUES.PROVISIONAL
      ? TABLE_COLUMNS.filter(
          (column) => !['name', 'department', 'role', 'job'].includes(column.key),
        )
      : TABLE_COLUMNS;

  return (
    <>
      <SnackBar
        open={showSuccessSnackbar}
        setOpen={setShowSuccessSnackbar}
        message={successMessage}
      />

      {showCreateUserButton && (
        <ButtonWrapper>
          <Button button_type="blue" padding="10px 16px" onClick={gotoCreateUserPage}>
            新規ユーザー登録
          </Button>
        </ButtonWrapper>
      )}
      {showTabs && (
        <TabsWrapper>
          <Tabs<UserListTab> tabs={tabOptions} value={selectedTab} onSelect={setSelectedTab} />
          <div hidden={selectedTab !== TAB_OPTION_VALUES.PROVISIONAL}>
            <WarningText>
              ユーザーの情報を間違えて登録した場合、削除してから再登録していただくようお願いします。
            </WarningText>
            <WarningText>
              登録メールを再送すると、以前送ったメール内のURLでは登録できなくなります。
            </WarningText>
          </div>
        </TabsWrapper>
      )}

      <ListTable columns={visibleColumns} data={users} countPerPageOptions={[50, 100, 200]} />
    </>
  );
};
