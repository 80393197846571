import { COMPANY_CONTRACT_STATUS, ICompany } from 'domain/entities/Company/Company';
import { USER_STATUS } from 'domain/entities/User/User';

export const COMPANY_USER_ROLE = {
  USER: 10,
  SUPER_USER: 20,
  ADMIN_USER: 30,
  SUPPORT_USER: 40,
} as const;

export type CompanyUserRole = (typeof COMPANY_USER_ROLE)[keyof typeof COMPANY_USER_ROLE];

export const COMPANY_USER_ROLES = [
  { key: COMPANY_USER_ROLE.USER, name: 'user' },
  { key: COMPANY_USER_ROLE.SUPER_USER, name: 'super_user' },
  { key: COMPANY_USER_ROLE.SUPPORT_USER, name: 'support_user' },
] as const;

export const COMPANY_USER_ROLE_NAMES = {
  [COMPANY_USER_ROLE.USER]: 'ユーザ',
  [COMPANY_USER_ROLE.SUPER_USER]: 'スーパーユーザ',
  [COMPANY_USER_ROLE.ADMIN_USER]: '管理者ユーザ',
  [COMPANY_USER_ROLE.SUPPORT_USER]: 'サポートユーザ',
} as const;

export const ASSIGN_STATUS = {
  JUST_INVITED: -20,
  WAITING_REVIEW: -10,
  BEFORE_SCHEDULING: 0,
  SCHEDULING: 10,
  SCHEDULED: 20,
  CANCELED_BY_INTRODUCED: 30,
  CANCELED_BY_COMPANY: 40,
  CANCELED_BY_ADMIN: 50,
} as const;

export const COMPANY_SELECTABLE_ASSIGN_USER_STATUS = [
  { key: ASSIGN_STATUS.BEFORE_SCHEDULING, name: 'assign_user_before_scheduling' },
  { key: ASSIGN_STATUS.SCHEDULING, name: 'assign_user_scheduling' },
  { key: ASSIGN_STATUS.SCHEDULED, name: 'assign_user_scheduled' },
] as const;

export const REGISTRATION_STEPS = {
  NEED_USER_REGISTRATION_STEP: 'need_user_registration_step',
  NEED_COMPANY_REGISTRATION_STEP: 'need_company_registration_step',
  NEED_REGISTRATION_CONFIRMATION: 'need_registration_confirmation',
  NOTHING: 'nothing',
} as const;
export type RegistrationStep = (typeof REGISTRATION_STEPS)[keyof typeof REGISTRATION_STEPS];

export interface ICompanyUser {
  id: number;
  company: ICompany;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  fb_user_id: string;
  enable_fb_message: boolean;
  email: string;
  password: string | null;
  birthday: string;
  gender: number;
  icon_image: string | null;
  status: number;
  role: CompanyUserRole;
  department: string | null;
  job: string | null;
  mail_news_receive: boolean;
  role_type: string;
  selected: boolean;
  phone_number: string | null;
  referral_code: string | null;
  registration_step: RegistrationStep | null;

  isAdmin: boolean;
  isSupportUser: boolean;
  isSuperUser: boolean;
  isUser: boolean;
  isContracted: boolean;
  isActive(): boolean;
}

export class CompanyUser implements ICompanyUser {
  constructor(
    public id: number,
    public company: ICompany,
    public last_name: string,
    public first_name: string,
    public last_name_kana: string,
    public first_name_kana: string,
    public fb_user_id: string,
    public enable_fb_message: boolean,
    public email: string,
    public password: string | null,
    public birthday: string,
    public gender: number,
    public icon_image: string | null,
    public status: number,
    public role: CompanyUserRole,
    public department: string | null,
    public job: string | null,
    public mail_news_receive: boolean,
    public role_type: string,
    public selected: boolean,
    public phone_number: string | null,
    public referral_code: string | null,
    public registration_step: RegistrationStep | null,
  ) {}

  public get isAdmin(): boolean {
    return this.role === COMPANY_USER_ROLE.ADMIN_USER;
  }

  public get isSupportUser(): boolean {
    return this.role === COMPANY_USER_ROLE.SUPPORT_USER;
  }

  public get isSuperUser(): boolean {
    return this.role === COMPANY_USER_ROLE.SUPER_USER;
  }

  public get isUser(): boolean {
    return this.role === COMPANY_USER_ROLE.USER;
  }

  public get isContracted(): boolean {
    return this.company.contract_status === COMPANY_CONTRACT_STATUS.CONTRACTED;
  }

  public isActive() {
    return this.status === USER_STATUS.ACTIVE;
  }
}
