import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { PublicHeader } from 'ui/components/company/features/PublicHeader/PublicHeader';
import { Input } from 'ui/components/company/elements/Input/Input';
import { Button } from 'ui/components/company/elements/Button/Button';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { ForgotPassword } from 'ui/components/company/screens/modals/ForgotPassword/ForgotPassword';
import { useLoginForm } from './useLoginForm';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  overflow-y: scroll;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const Title = styled.h1`
  font-size: var(--font-size--xx-large);
  font-weight: var(--font-weight--bolder);
  text-align: center;
  margin-block: 60px 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--spr-white);
  max-width: 500px;
  margin-inline: auto;
  padding: 40px 16px;

  .input {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-bottom: 40px;
  }
  .button {
    margin-block: 30px 16px;
  }
`;

const UserLoginLink = styled.div`
  position: relative;

  margin-top: 20px;
  padding-top: 24px;

  &:before {
    content: '.....';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    letter-spacing: 0.5em;
    color: var(--gray-light-1);
  }
`;

const RegistrationButtonWrapper = styled.div`
  margin-block: 16px;
`;

/**
 * 企業系ログインフォーム
 */
export const LoginForm: React.FC = () => {
  const { control, errors, redirectIfLoggedIn, loading, login, register } = useLoginForm();

  useEffect(() => {
    redirectIfLoggedIn();
  }, []);

  return (
    <Wrapper>
      <PublicHeader />
      <Title>企業ユーザーログイン</Title>
      <Form>
        <div className="input">
          <FormLabel title="メールアドレス" isRequired />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="email" error={errors.email?.message} />
            )}
          />
        </div>
        <div className="input">
          <FormLabel title="パスワード" isRequired />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                placeholder="password"
                error={errors.password?.message}
              />
            )}
          />
        </div>

        <span className="button">
          <Button button_type="dark" onClick={login} disabled={loading}>
            ログイン(登録済の方)
          </Button>
        </span>
        <div>または</div>
        <RegistrationButtonWrapper>
          <Button button_type="weak_red" width={`320px`} onClick={register} disabled={loading}>
            新規登録
          </Button>
        </RegistrationButtonWrapper>

        <span className="forgot">
          <ForgotPassword />
        </span>

        <UserLoginLink>
          <Link to={'/login/'}>個人ユーザー用ログイン</Link>
        </UserLoginLink>
      </Form>
    </Wrapper>
  );
};
