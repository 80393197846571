import { diContainerContext } from 'application/contexts/useDiContainer';
import { ICompanyMoneyForwardDepartmentsQuery } from 'application/querySchemas/ICompanyMoneyForwardDepartmentsQuery';
import { ICompanyQuotationQuery } from 'application/querySchemas/ICompanyQuotationQuery';
import { ICompanyBillingRepository } from 'application/repositorySchemas/ICompanyBillingRepository';
import { ICompanyMoneyForwardDepartment } from 'domain/valueObjects/CompanyMoneyForward/CompanyMoneyForwardDepartment';
import { ProjectBillings } from 'domain/valueObjects/ProjectBillings/ProjectBillings';
import { useCallback, useContext, useEffect, useState } from 'react';

export interface FirstMessagePaymentConfirmProps {
  projectId?: number;
  onPaymentComplement: () => Promise<void>;
  closeModal: () => void;
  handleStartPaymentSession: (sessionId: string) => void;
}

export const useFirstMessagePaymentConfirm = ({
  projectId,
  onPaymentComplement,
  ...props
}: FirstMessagePaymentConfirmProps) => {
  if (!projectId) {
    throw new Error('projectIdが指定されていません');
  }
  const diContainer = useContext(diContainerContext);
  const companyQuotationQuery =
    diContainer.resolve<ICompanyQuotationQuery>('ICompanyQuotationQuery');
  const companyBillingRepository = diContainer.resolve<ICompanyBillingRepository>(
    'ICompanyBillingRepository',
  );

  const companyMoneyForwardDepartmentsQuery =
    diContainer.resolve<ICompanyMoneyForwardDepartmentsQuery>(
      'ICompanyMoneyForwardDepartmentsQuery',
    );

  const [showAddedMessage, setShowAddedMessage] = useState(false);

  // MF部署情報
  const [moneyForwardDepartments, setMoneyForwardDepartments] = useState<
    ICompanyMoneyForwardDepartment[] | null
  >(null);
  const [departmentsRefetching, setDepartmentsRefetching] = useState(false);

  // 決済情報
  const [paymentInfo, setPaymentInfo] = useState<ProjectBillings | null>(null);
  // 決済中
  const [requesting, setRequesting] = useState(false);
  // 決済進捗
  const [progress, setProgress] = useState(0);
  // 決済後のメッセージ送信まで完了
  const [completed, setCompleted] = useState(false);

  const fetchDepartments = async () => {
    const departments = await getMoneyForwardDepartments();
    setMoneyForwardDepartments(departments);
  };

  const refetchDepartments = async () => {
    setDepartmentsRefetching(true);
    await fetchDepartments();
    setDepartmentsRefetching(false);
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchPaymentInfo = async () => {
    const response = await companyQuotationQuery.getProjectBillings(projectId);
    response && setPaymentInfo(response);
  };

  const confirmPayment = async (departmentId: string) => {
    if (!projectId) {
      return;
    }

    setRequesting(true);

    try {
      setTimeout(() => setProgress(90), 0); // 90%まで進めておく

      const result = await companyBillingRepository.processFirstContactBilling({
        projectId,
        departmentId,
      });
      if (result.redirectUrl) {
        alert('クレジットカード決済が選択されているため、外部サービスを用いた決済画面へ遷移します');
        props.handleStartPaymentSession(result.internalId);
        window.location.href = result.redirectUrl;
        return;
      }

      await onPaymentComplement();

      setProgress(100);
      setTimeout(() => setCompleted(true), 1000); // 100%を少し見せてから完了表示へ
    } catch {
      setRequesting(false);
    }
  };

  const getMoneyForwardDepartments = useCallback(async () => {
    try {
      return await companyMoneyForwardDepartmentsQuery.getDepartments();
    } catch {
      throw new Error('請求先情報の取得に失敗しました');
    }
  }, [companyMoneyForwardDepartmentsQuery]);

  const getProjectQuotation = async (departmentId: string) => {
    if (!projectId) {
      return;
    }
    try {
      setRequesting(true);
      setProgress(50); // 90%まで進めておく
      setTimeout(() => setProgress(90), 1000);
      const blob = await companyQuotationQuery.getProjectQuotation({ projectId, departmentId });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `Spready_お見積書_${new Date().toLocaleDateString()}.pdf`;

      link.click();
      window.URL.revokeObjectURL(url);
    } catch {
      throw new Error('見積書のダウンロードに失敗しました');
    } finally {
      setRequesting(false);
      setProgress(0);
    }
  };

  return {
    paymentInfo,
    requesting,
    progress,
    completed,
    fetchPaymentInfo,
    confirmPayment,
    getProjectQuotation,
    moneyForwardDepartments,
    refetchDepartments,
    showAddedMessage,
    setShowAddedMessage,
    departmentsRefetching,
  };
};
