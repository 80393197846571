import { companyUserContext } from 'application/contexts/useCompanyUser';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabOption } from '../../elements/Tabs/Tabs';
import { PreCompanyUser } from 'domain/entities/CompanyUser/PreCompanyUser';
import { COMPANY_USER_ROLE_NAMES, ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { PreCompanyUserRepository } from 'interfaceAdapter/repositories/PreCompanyUser';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { PreCompanyUserQuery } from 'interfaceAdapter/queries/PreCompanyUser';

export const TAB_OPTION_VALUES = {
  ACTIVE: 'active',
  PROVISIONAL: 'provisional',
} as const;
export type UserListTab = (typeof TAB_OPTION_VALUES)[keyof typeof TAB_OPTION_VALUES];
const tabOptions: TabOption<UserListTab>[] = [
  {
    label: '有効',
    value: TAB_OPTION_VALUES.ACTIVE,
  },
  {
    label: '仮登録中',
    value: TAB_OPTION_VALUES.PROVISIONAL,
  },
];

export interface UserListTableRow {
  id: number;
  name: string;
  email: string;
  role: string;
  department: string | null;
  job: string | null;
}

export const useUserListTable = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve<CompanyUserRepository>(CompanyUserRepository);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  const preCompanyUserQuery = diContainer.resolve(PreCompanyUserQuery);
  const preCompanyUserRepository = diContainer.resolve(PreCompanyUserRepository);
  const { user } = useContext(companyUserContext);
  const [users, setUsers] = useState<ICompanyUser[] | PreCompanyUser[]>([]);
  const [requesting, setRequesting] = useState(false);
  const [selectedTab, setSelectedTab] = useState<UserListTab>('active');
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const formattedUsers = useMemo(() => {
    return users.map((u) => ({
      id: u.id,
      name: `${u.last_name} ${u.first_name}`,
      email: u.email,
      role: COMPANY_USER_ROLE_NAMES[u.role],
      department: u.department,
      job: u.job,
    }));
  }, [users]);

  const showCreateUserButton = useMemo(
    () => user.isContracted && (user.isSuperUser || user.isSupportUser),
    [user],
  );
  const showTabs = useMemo(() => user.isSuperUser || user.isSupportUser, [user]);

  const gotoCreateUserPage = useCallback(() => navigate('/company/main/users/invite/'), []);
  const gotoEditUserPage = useCallback(
    (id: number) => navigate(`/company/main/users/edit/${id}/`),
    [],
  );

  const getCompanyUsers = useCallback(async () => {
    const users = await companyUserQuery.getAll();
    setUsers(
      user.isSuperUser || user.isSupportUser ? users : users.filter((u) => u.id === user.id),
    );
  }, [user]);

  const getPreCompanyUsers = useCallback(async () => {
    const users = await preCompanyUserQuery.getAll();
    setUsers(users);
  }, []);

  const resendRegisterEmail = useCallback(async (id: number) => {
    setRequesting(true);
    try {
      await preCompanyUserRepository.resendEmail(id);
      setShowSuccessSnackbar(true);
      setSuccessMessage('本登録メールを再送しました');
    } finally {
      setRequesting(false);
    }
  }, []);

  const disableUser = useCallback(async (id: number) => {
    if (!confirm('本当に削除しますか？')) {
      return;
    }
    setRequesting(true);
    try {
      await companyUserRepository.disable(id);
      setShowSuccessSnackbar(true);
      setSuccessMessage('ユーザーを無効化しました');

      getCompanyUsers();
    } finally {
      setRequesting(false);
    }
  }, []);

  const deletePreUser = useCallback(async (id: number) => {
    setRequesting(true);
    try {
      await preCompanyUserRepository
        .delete(id)
        .then(() => preCompanyUserQuery.getAll().then(setUsers));
      setShowSuccessSnackbar(true);
      setSuccessMessage('仮登録中のユーザーを削除しました');

      const newUsers = await preCompanyUserQuery.getAll();
      setUsers(newUsers);
    } finally {
      setRequesting(false);
    }
  }, []);

  useEffect(() => {
    if (selectedTab === TAB_OPTION_VALUES.ACTIVE) {
      getCompanyUsers();
    }
    if (selectedTab === TAB_OPTION_VALUES.PROVISIONAL) {
      getPreCompanyUsers();
    }
  }, [selectedTab]);

  return {
    users: formattedUsers,
    requesting,
    successMessage,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    showCreateUserButton,
    showTabs,
    gotoCreateUserPage,
    gotoEditUserPage,
    tabOptions,
    selectedTab,
    setSelectedTab,
    resendRegisterEmail,
    disableUser,
    deletePreUser,
  };
};
