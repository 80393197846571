import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ICompanySupportChat } from 'domain/valueObjects/companySupportChat/CompanySupportChat';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { SupportChatMessageQuery } from 'interfaceAdapter/queries/SupportChatMessage';
import { Message, MESSAGE_USER_TYPE } from 'domain/entities/Message/Message';
import { useParams } from 'react-router-dom';
import type { IUserFactory } from 'domain/entities/factories/User';
import { ISupportChatTitleUser } from 'domain/entities/User/SupportChatTitleUser';
import { companyUserContext } from 'application/contexts/useCompanyUser';

export interface ChatDetailProps {
  room?: ICompanySupportChat;
}

export const useSupportChatDetail = ({ room }: ChatDetailProps) => {
  const params = useParams();
  const { user: adminUser } = useContext(companyUserContext);
  const diContainer = useContext(diContainerContext);
  const supportChatMessageQuery = diContainer.resolve(SupportChatMessageQuery);
  const userFactory = diContainer.resolve<IUserFactory>('IUserFactory');
  const ref = useRef<HTMLDivElement>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [user, setUser] = useState<ISupportChatTitleUser | null>(null);

  const fetchMessages = async () => {
    if (roomId === null) return;
    const messages = await supportChatMessageQuery.getMessages(roomId);
    setMessages(messages.reverse());
  };

  useMemo(() => {
    const userMessage = messages.find((message) => message.user_type === MESSAGE_USER_TYPE.USER);
    if (!userMessage) return;

    const built = userFactory.buildSupportChatTitleUser({
      id: userMessage.user_id,
      last_name: userMessage.user_last_name,
      first_name: userMessage.user_first_name,
      icon_image: userMessage.user_icon_image ?? '',
    });
    setUser(built);
  }, [messages]);

  const scrollToBottom = () => {
    if (!ref.current) return;
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  let latestTimestamp = 0;
  const onSendMessage = async () => {
    if (roomId === null) return;

    const messages = await supportChatMessageQuery.getMessages(roomId);

    if (messages.length > 0 && latestTimestamp !== messages[0].timestamp) {
      setMessages((state) => [...state, messages[0]]);
      latestTimestamp = messages[0].timestamp;
    }
  };

  useEffect(() => {
    if (params.roomId && params.roomId !== 'rooms') {
      setRoomId(params.roomId);
    }
  }, []);

  useEffect(() => {
    if (!room) return;
    setRoomId(room.room_id);
  }, [room]);

  useEffect(() => {
    fetchMessages();
  }, [roomId]);

  // メッセージのフェッチ後に最下部（最新メッセージ箇所）にスクロールするようにしている
  useEffect(() => {
    scrollToBottom();
  }, [roomId, messages]);

  return {
    ref,
    user,
    adminUser,
    roomId,
    messages,
    onSendMessage,
  };
};
