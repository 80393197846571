import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  // setOgTitle,
  // setOgDescription,
  PageMeta,
  removeHeadChildren,
  resetContents,
  removeTitle,
  setCanonical,
  // setMetaDescription,
  // setTitle,
  setOgUrl,
  // setMetaTwitterTitle,
  // setMetaTwitterDescription,
} from 'utils/headInjection';

// TODO: Fetchした内容をもとにtitle, description, og imageを設定できるように拡張する
export const useHead = (): Pick<PageMeta, 'path'> => {
  const path = useLocation().pathname;
  // const title = 'Spreadyサービス利用規約 （個人様向け規約）';
  // const description = title;

  useEffect(() => {
    // setTitle(title, document);
    // const metaDescription = setMetaDescription(description, document);
    const canonical = setCanonical(path, document);

    // const metaTwitterTitle = setMetaTwitterTitle(title, document);
    // const metaTwitterDescription = setMetaTwitterDescription(description, document);
    // const ogTitle = setOgTitle(title, document);
    // const ogDescription = setOgDescription(description, document);
    const ogUrl = setOgUrl(path, document);

    return () => {
      removeTitle(document);
      removeHeadChildren(
        [
          canonical,
          // metaDescription
        ],
        document,
      );
      resetContents([
        // metaTwitterTitle,
        // metaTwitterDescription,
        // ogTitle,
        // ogDescription,
        ogUrl,
      ]);
    };
  });

  return {
    // title,
    // description,
    path,
  };
};
