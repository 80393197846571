import styled from '@emotion/styled';
import { useNotification } from './useNotification';
import { useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NoNotificationImg from 'ui/images/com/no-notification.svg';
import { SimpleModalContent } from 'ui/components/company/elements/SimpleModalContent/SimpleModalContent';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { INews } from 'domain/entities/News/News';
import dayjs from 'dayjs';

const Container = styled.div``;

const NotificationContainer = styled.div`
  width: 260px;
  height: 100%;
  background-color: var(--color-pale-gray-back);
  border-radius: 16px 16px 0 0;
  padding: 16px 24px;
`;

const Title = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: flex-end;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    font-weight: 700;
    font-size: 18px;
    height: 20px;
    line-height: 20px;
    margin: 0 0 0 4px;
  }
`;

const ItemContainer = styled.div`
  height: calc(100vh - 150px);
  @supports (height: 100svh) {
    height: calc(100svh - 150px);
  }
  overflow-y: auto;
`;

const NotificationItem = styled.div`
  border-bottom: 1px solid var(--gray-light-1);
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-back);
  }

  p {
    padding: 12px 4px;
    margin: 0;
  }
`;

const NoNotification = styled.div`
  margin: 80px 5px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 60px;
  }

  p {
    color: #b3b3b3;
  }
`;

/**
 * 企業側ダッシュボードのお知らせ一覧
 */
export const Notification: React.FC = () => {
  const navigate = useNavigate();
  const { openModal, createModal } = useModal();
  const [newsOnModal, setNewsOnModal] = useState<INews | null>(null);
  const {
    news,
    noNotifications,
    waitingFirstContactCount,
    needFirstContactMeetRoomIds,
    unreadRoomCount,
    fetchNews,
  } = useNotification();

  const descriptionTxt = newsOnModal?.start_at
    ? `${dayjs(newsOnModal.start_at).format('YYYY/MM/DD')}\n${newsOnModal?.body}`
    : newsOnModal?.body || '';

  useEffect(() => {
    fetchNews();
  }, []);

  const goToCastings = () => {
    navigate('/company/main/castings/?status=waitingContact&assign=all');
  };

  const goToChatRoom = (roomId: string) => {
    navigate(`/company/main/chat/${roomId}/?type=mr`);
  };

  const goToChatRooms = () => {
    navigate('/company/main/chat/rooms');
  };

  const onClickNews = (newsObj: INews) => {
    return () => {
      setNewsOnModal(newsObj);
      openModal();
    };
  };

  return (
    <Container>
      <NotificationContainer>
        <Title>
          <NotificationsIcon style={{ width: 16, height: 16 }} />
          <h2>お知らせ</h2>
        </Title>
        <ItemContainer>
          {noNotifications && (
            <NoNotification>
              <img src={NoNotificationImg} alt="お知らせがない" />
              <p>お知らせがありません</p>
            </NoNotification>
          )}

          {unreadRoomCount > 0 && (
            <NotificationItem onClick={goToChatRooms}>
              <p>
                未読のメッセージが<span>{unreadRoomCount}件</span>あります。
              </p>
            </NotificationItem>
          )}

          {waitingFirstContactCount > 0 && (
            <NotificationItem onClick={goToCastings}>
              <p>
                <span>{waitingFirstContactCount}件</span>の未対応の紹介があります。
              </p>
            </NotificationItem>
          )}

          {needFirstContactMeetRoomIds &&
            needFirstContactMeetRoomIds.map((roomId) => (
              <NotificationItem key={roomId} onClick={() => goToChatRoom(roomId)}>
                <p>会いたい成立が発生しています。メッセージを送信してください。</p>
              </NotificationItem>
            ))}

          {news.map((n) => (
            <NotificationItem key={n.id}>
              <p onClick={onClickNews(n)}>
                【{n.start_at && dayjs(String(n.start_at)).format('YYYY/MM/DD')}】&nbsp;{n.title}
              </p>
            </NotificationItem>
          ))}
          {/* お知らせ用モーダル */}
          {createModal(
            <SimpleModalContent title={newsOnModal?.title ?? ''} description={descriptionTxt} />,
          )}
        </ItemContainer>
      </NotificationContainer>
    </Container>
  );
};
