import fixture from './CompanyChatRoomMembersGet.get.json';
import { fetchFixture } from 'utils/fetchFixture';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICompanyChatRoomMembersGetClient,
  IGetInput,
  IGetResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMembersGet';

export class CompanyChatRoomMembersGetClient implements ICompanyChatRoomMembersGetClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, roomId, mock = false } = input;
    const path = `${API_LOCATION_SPREADY}/company/chat_rooms/members`;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    const body = JSON.stringify({ room_id: roomId });

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(path, {
            method,
            headers,
            body,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
