import { SessionManageForm } from 'ui/components/company/patterns/SessionManageForm/SessionManageForm';

/**
 * セッション編集ページ
 */
const SessionEdit = () => {
  return <SessionManageForm />;
};

export default SessionEdit;
