import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IFirstContactBillingClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/IFirstContactBillingClient';

export class FirstContactBillingClient implements IFirstContactBillingClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const { token, projectId } = input;
    const PATH = `${API_LOCATION_SPREADY}/company/projects/${projectId}/first_contact_bill`;
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN-C': token,
    };
    try {
      const response = await fetch(`${PATH}`, {
        method,
        headers,
        body: JSON.stringify({ department_id: input.departmentId }),
      });

      const json = await response.json();

      return {
        success: json.data.success,
        redirectUrl: json.data.redirect_url,
        internalId: json.data.internal_id,
      };
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
