import styled from '@emotion/styled';
import { RegisterForm } from 'ui/components/user/panes/RegisterForm/RegisterForm';
import bg from 'ui/images/user/login/background.png';
import bgSp from 'ui/images/user/login/background_sp.png';
import { mq } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  @supports (height: 100svh) {
    min-height: 100svh;
  }
  padding-block: 60px;
  background-image: url(${bg});
  background-color: var(--spr-primary-pale);
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;

  ${mq('SP')} {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 40px 16px;
    background-image: url(${bgSp});
    background-position: top center;
    background-size: contain;
  }
`;

/**
 * 会員登録レイアウト
 */
export const RegisterLayout: React.FC = () => {
  return (
    <Wrapper>
      <RegisterForm />
    </Wrapper>
  );
};
