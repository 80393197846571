import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Controller } from 'react-hook-form';

import { Button } from 'ui/components/company/elements/Button/Button';
import { FormLabel } from 'ui/components/company/elements/FormLabel/FormLabel';
import { Input } from 'ui/components/company/elements/Input/Input';
import { Select } from 'ui/components/company/elements/Select/Select';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { TextArea } from 'ui/components/company/elements/TextArea/TextArea';
import { ValidationError } from 'ui/components/company/elements/ValidationError/ValidationError';
import { useProjectTicketConsumptionQuery } from 'useProjectTicketConsumptionQuery';
import { useSendMeetRequest } from './useSendMeetRequest';

const Wrapper = styled.div`
  width: 460px;
  padding: 24px 30px;
`;

const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -10px;
`;

const Icon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  margin-block: 12px 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  text-align: center;
`;

/**
 * 会いたいリクエスト 送信モーダル
 * @description 新規送信と再送信の両方に対応
 */
export const SendMeetRequest: React.FC = () => {
  const {
    control,
    formState: { errors },
    projects,
    requesting,
    createModal,
    showSnackbar,
    setShowSnackbar,
    close,
    save,
    selectedProjectId,
  } = useSendMeetRequest();

  return createModal(
    <>
      <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message="リクエストを送信しました" />

      <Wrapper>
        <Close>
          <Icon onClick={close} />
        </Close>

        <FormLabel title="メッセージ" isRequired />
        <InputWrapper>
          <Controller
            name="topic"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={5}
                placeholder={`どんな事を話したいか教えてください\nex. 普段のコミュニティ参加活動について`}
                error={errors.topic?.message}
              />
            )}
          />
        </InputWrapper>

        <FormLabel title="面談担当者情報" isRequired />
        <InputWrapper>
          <Controller
            name="attender"
            control={control}
            render={({ field }) => <Input {...field} placeholder="役職やお名前など" />}
          />
          {errors.attender && <ValidationError message={errors.attender?.message} />}
        </InputWrapper>

        <FormLabel title="お話をしたいセッション" isRequired />
        <InputWrapper>
          <Controller
            name="project_id"
            control={control}
            render={({ field }) => (
              <Select {...field} options={projects} placeholder="選択してください" />
            )}
          />
          {errors.project_id && <ValidationError message={errors.project_id?.message} />}

          <SessionTicketConsumption projectId={selectedProjectId} />
        </InputWrapper>

        <ButtonWrapper>
          <Button button_type="dark" width="200px" disabled={requesting} onClick={save}>
            会いたいを送信する
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </>,
    {
      onClose: close,
    },
  );
};

const SessionTicketConsumption = ({ projectId }: { projectId: number | undefined }) => {
  const {
    // fetching,
    data,
  } = useProjectTicketConsumptionQuery({
    projectId: projectId,
  });

  if (!data) {
    return null;
  }

  if (!data.consumed) {
    return (
      <div
        style={{
          marginTop: '8px',
          padding: '.8rem',
          borderRadius: '.2rem',
          background: 'var(--spr-warn-pale)',
        }}
      >
        選択された「お話をしたいセッション」はまだチケットを消費しておりません。会いたいリクエストの送信は無料で出来ますが、ユーザーが会いたいリクエストを承認したタイミングで、まだチケットが消費されてない場合は、
        <b>チケットが1枚消費されます</b>。
        <br />
        ※消費するチケットをお持ちでない場合は<b>チケット1枚の購入処理を実施</b>
        し、請求書を発行いたします。
        <hr />
        <span>
          ・請求書の宛先については
          <a
            style={{ textDecoration: 'underline' }}
            href="/company/billing-info/"
            target="_blank"
            rel="noreferrer"
          >
            こちら
          </a>
          で設定ください
        </span>
        <br />
        ・チケットの保有状況については
        <a
          style={{ textDecoration: 'underline' }}
          href="/company/main/contract/"
          target="_blank"
          rel="noreferrer"
        >
          こちら
        </a>
        をご確認ください
      </div>
    );
  }

  return null;
};
