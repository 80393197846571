import { inject, injectable } from 'tsyringe';
import { type ICompanyUserRepository } from 'application/repositorySchemas/ICompanyUserRepository';
import {
  IPutInput,
  type ICompanyUserClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUserClient';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyUserPasswordClient } from 'interfaceAdapter/gatewaySchemas/ICompanyUserPasswordClient';
import { type ICompanyUserLogoutClient } from 'interfaceAdapter/gatewaySchemas/ICompanyUserLogoutClient';
import { type ICompanyLoginByEmailClient } from 'interfaceAdapter/gatewaySchemas/ICompanyLoginByEmailClient';
import { type ICompanySendPasswordResetEmailClient } from 'interfaceAdapter/gatewaySchemas/ICompanySendPasswordResetEmailClient';
import { type ICompanyRegistrationSendMailClient } from 'interfaceAdapter/gatewaySchemas/ICompanyRegistrationSendMailClient';
import { type ICompanyInvitedRegistrationSendMailClient } from 'interfaceAdapter/gatewaySchemas/ICompanyInvitedRegistrationSendMailClient';
import { type IPreCompanyUserByUrlIdClient } from 'interfaceAdapter/gatewaySchemas/IPreCompanyUserByUrlIdClient';
import { type ICompanyRegistrationPasswordClient } from 'interfaceAdapter/gatewaySchemas/ICompanyRegistrationPasswordClient';
import {
  type ICompanyUserProfileRegistrationClient,
  IPostInput as IProfileRegistrationInput,
} from 'interfaceAdapter/gatewaySchemas/ICompanyUserProfileRegistrationClient';
import { type ICompanyUserRegistrationConfirmClient } from 'interfaceAdapter/gatewaySchemas/ICompanyUserRegistrationConfirmClient';
import {
  IPostInput,
  type ICompanyInvitedRegistrationUserProfileClient,
} from 'interfaceAdapter/gatewaySchemas/ICompanyInvitedRegistrationUserProfileClient';
import { type ICompanyUserFactory } from 'domain/entities/factories/CompanyUser';
import { buildResponse, HttpBadRequest, HttpNotFound, HttpGone } from 'domain/types/httpResponse';

@injectable()
export class CompanyUserRepository implements ICompanyUserRepository {
  public readonly token: string;

  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyUserClient')
    public readonly companyUserClient: ICompanyUserClient,
    @inject('ICompanyLoginByEmailClient')
    public readonly companyLoginByEmail: ICompanyLoginByEmailClient,
    @inject('ICompanyUserLogoutClient')
    public readonly companyUserLogoutClient: ICompanyUserLogoutClient,
    @inject('ICompanySendPasswordResetEmailClient')
    public readonly companySendPasswordResetEmailClient: ICompanySendPasswordResetEmailClient,
    @inject('ICompanyUserPasswordResetClient')
    public readonly companyUserPasswordResetClient: ICompanyUserPasswordClient,
    @inject('ICompanyRegistrationSendMailClient')
    public readonly companyRegistrationSendMailClient: ICompanyRegistrationSendMailClient,
    @inject('IPreCompanyUserByUrlIdClient')
    public readonly preCompanyUserByUrlIdClient: IPreCompanyUserByUrlIdClient,
    @inject('ICompanyRegistrationPasswordClient')
    public readonly companyRegistrationPasswordClient: ICompanyRegistrationPasswordClient,
    @inject('ICompanyUserProfileRegistrationClient')
    public readonly companyUserProfileRegistrationClient: ICompanyUserProfileRegistrationClient,
    @inject('ICompanyUserRegistrationConfirmClient')
    public readonly confirmRegistrationClient: ICompanyUserRegistrationConfirmClient,
    @inject('ICompanyInvitedRegistrationSendMailClient')
    public readonly companyInvitedRegistrationSendMailClient: ICompanyInvitedRegistrationSendMailClient,
    @inject('ICompanyInvitedRegistrationUserProfileClient')
    public readonly companyInvitedRegistrationUserProfileClient: ICompanyInvitedRegistrationUserProfileClient,
    @inject('ICompanyUserFactory')
    public readonly factory: ICompanyUserFactory,
  ) {
    this.token = this.companyUserToken.get() ?? '';
  }

  public async disable(id: number) {
    const { message } = await this.companyUserClient.delete({ token: this.token, id });
    if (message) {
      throw new Error(message);
    }
  }

  public async edit(companyUserParams: IPutInput['companyUserParams']) {
    const { message } = await this.companyUserClient.put({ token: this.token, companyUserParams });
    if (message) {
      throw new Error(message);
    }
  }

  public loginByEmail = async (email: string, password: string) => {
    const { data, message } = await this.companyLoginByEmail.post({ email, password });
    if (message) {
      throw new Error(message);
    }
    this.companyUserToken.remove();
    this.companyUserToken.set(data.api_token);
    return this.factory.build(data);
  };

  public logout = async () => {
    await this.companyUserLogoutClient.post({ token: this.token });
  };

  public sendResetPasswordEmail = async (email: string) => {
    const { message } = await this.companySendPasswordResetEmailClient.post({ email });
    if (message) {
      throw new Error(message);
    }
  };

  public async resetPassword(code: string, password: string) {
    const { data, message } = await this.companyUserPasswordResetClient.post({
      code,
      password,
    });

    if (message) {
      throw new Error(message);
    }

    return { result: data };
  }

  public registerSendMail = async (email: string) => {
    const { message } = await this.companyRegistrationSendMailClient.post({
      email,
    });
    if (message) {
      throw new Error(message);
    }
  };

  public sendInvitedRegistrationEmail = async (email: string) => {
    const { message } = await this.companyInvitedRegistrationSendMailClient.post({
      token: this.token,
      email,
    });
    if (message) {
      throw new Error(message);
    }
  };

  public registerInvitedUserProfile = async (
    companyUserParams: IPostInput['companyUserParams'],
  ): Promise<void> => {
    const { message } = await this.companyInvitedRegistrationUserProfileClient.post({
      token: this.token,
      companyUserParams,
    });
    if (message) {
      throw new Error(message);
    }
  };

  public fetchPreCompanyUserByUrlId = async (urlId: string) => {
    const { data, message, status } = await this.preCompanyUserByUrlIdClient.get({ url_id: urlId });
    const httpResponse = buildResponse(status, message);
    if (httpResponse instanceof HttpBadRequest) {
      throw new HttpBadRequest(message);
    }
    if (httpResponse instanceof HttpGone) {
      throw new HttpGone(message);
    }
    if (httpResponse instanceof HttpNotFound) {
      throw Error(message);
    }
    return { data, status };
  };

  public registerPassword = async (urlId: string, password: string) => {
    const { data, message } = await this.companyRegistrationPasswordClient.post({
      urlId,
      password,
    });
    if (!data.api_token) {
      throw new Error('処理に失敗しました');
    }
    if (message) {
      throw new Error(message);
    }
    this.companyUserToken.set(data.api_token);
    return { data };
  };

  public registerProfile = async (
    companyUserParams: IProfileRegistrationInput['companyUserParams'],
  ) => {
    const { message } = await this.companyUserProfileRegistrationClient.post({
      token: this.token,
      companyUserParams,
    });
    if (message) {
      throw new Error(message);
    }
  };

  public confirmRegistration = async () => {
    const { message } = await this.confirmRegistrationClient.post({ token: this.token });
    if (message) {
      throw new Error(message);
    }
  };
}
