import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';

const Wrapper = styled.div`
  padding: 20px 40px 120px;
`;

const Desc = styled.p<{ small?: boolean }>`
  margin-top: ${({ small }) => (small ? '4px' : '40px')};
  margin-bottom: 20px;
  font-size: ${({ small }) => (small ? 'var(--font-x-large)' : 'var(--font-xx-large)')};
  font-weight: var(--font-bold);
  text-align: center;
`;

export const ProjectShareDisableComplete: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <PageTitle title="募集案件の作成完了" size="large" />

      <Desc>募集ページが公開されました</Desc>

      <ActionButton
        bgColor="secondary-dark"
        style={{ margin: '0 auto' }}
        onClick={() => navigate('/company/main/projects/')}
      >
        セッション一覧に戻る
      </ActionButton>
    </Wrapper>
  );
};
