import type { IUser } from './User';

export type ISupportChatTitleUser = {
  id: number;
} & Pick<IUser, 'last_name' | 'first_name' | 'icon_image'>;

export class SupportChatTitleUser implements ISupportChatTitleUser {
  constructor(
    public readonly id: number,
    public readonly last_name: string,
    public readonly first_name: string,
    public readonly icon_image: string,
  ) {}
}
