import styled from '@emotion/styled';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { IProject } from 'domain/entities/Project/Project';
import { removeCompanyLabel } from 'utils/string';

const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  overflow-x: auto;
  width: 100%;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  min-width: auto;
  flex: 1;

  img {
    border: 1px solid var(--spr-black);
    object-fit: cover;
  }
`;

const UserDesc = styled.div`
  margin: 10px 0 10px 12px;
  flex-grow: 1;
  color: var(--spr-black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserName = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
`;

const UserJob = styled.span`
  font-size: 10px;
`;

const CompanyName = styled.div`
  margin: 5px auto 0;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
`;

const Title = styled.div`
  width: 100%;
  padding-top: 5px;
  border-top: 1px dashed var(--gray-light-2);
  font-weight: var(--font-weight--bolder);
  font-size: 12px;
  color: #d7981c;
`;

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

interface Props {
  project: IProject;
  title?: string;
  onCompanyClick?: () => void;
}

/**
 * セッション担当者情報を表示
 */
export const ProjectManagementUsers: React.FC<Props> = ({
  project,
  title,
  onCompanyClick = () => {},
}) => {
  return (
    <Wrapper>
      {project.management_users?.map((management_user) => (
        <UserWrapper key={management_user.id}>
          <IconWrapper>
            <UserIcon
              src={management_user.icon_image}
              alt={`${management_user.last_name} ${management_user.first_name}`}
              size={40}
              isActive={management_user.isActive()}
            />
          </IconWrapper>
          <UserDesc>
            <UserName>
              {management_user.last_name} {management_user.first_name}
            </UserName>
            {!project.privacy && (management_user.job || management_user.department) && (
              <UserJob>
                {removeCompanyLabel(management_user.department ?? '')} {management_user.job}
              </UserJob>
            )}
            {!!project && (
              <CompanyName onClick={onCompanyClick}>{project.company?.name ?? ''}</CompanyName>
            )}
            {title && <Title>{title}</Title>}
          </UserDesc>
        </UserWrapper>
      ))}
    </Wrapper>
  );
};
