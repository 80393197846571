import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { Header } from 'ui/components/company/panes/Header/Header';
import { Sidebar } from 'ui/components/company/panes/Sidebar/Sidebar';
import { useCompanyStandard } from './useCompanyStandard';
import { CompanyDrawer } from 'ui/components/company/screens/drawers/CompanyDrawer/CompanyDrawer';
import { SendMeetRequest } from '../../screens/modals/SendMeetRequest/SendMeetRequest';

const Wrapper = styled.div<{ isSideOpen: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  margin: 0 auto;

  .header {
    height: var(--height-company-pc-header);
  }

  .sidebar {
    position: fixed;
    top: var(--height-company-pc-header);
    left: 0;
    z-index: var(--z-company-sidebar);
    width: 250px;
    height: calc(100vh - var(--height-company-pc-header));
    @supports (height: 100svh) {
      height: calc(100svh - var(--height-company-pc-header));
    }
    transform: ${(props) => (props.isSideOpen ? 'translateX(0)' : 'translateX(-250px)')};
    transition: transform 0.3s ease-out;
  }

  .main {
    flex: 1;
    padding: 16px 30px 60px;
    overflow-y: auto;
  }
`;

const Backdrop = styled.div<{ isSideOpen: boolean }>`
  position: fixed;
  top: var(--height-company-pc-header);
  left: 0;
  width: 100vw;
  height: calc(100vh - var(--height-company-pc-header));
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-company-pc-header));
  }
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-company-sidebar-bg);
  display: ${(props) => (props.isSideOpen ? 'block' : 'none')};
`;

/**
 * 企業系ログイン後ページの共通処理
 */
export const CompanyStandard: React.FC = () => {
  const { mainRef, canEnter, isSideOpen, setIsSideOpen } = useCompanyStandard();
  return !canEnter ? null : (
    <Wrapper isSideOpen={isSideOpen}>
      <div className="header">
        <Header />
      </div>
      <div className={`sidebar ${isSideOpen ? 'open' : ''}`}>
        <Sidebar />
      </div>
      <div ref={mainRef} className="main">
        <Outlet />
      </div>
      <CompanyDrawer />
      <SendMeetRequest />

      <Backdrop isSideOpen={isSideOpen} onClick={() => setIsSideOpen(false)} />
    </Wrapper>
  );
};
