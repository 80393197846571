import { type ICompanyBillingSettingRepository } from 'application/repositorySchemas/ICompanyBillingSettingRepository';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import {
  type ICompanyBillingSettingClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ICompanyBillingSettingClient';
import { inject, injectable } from 'tsyringe';

@injectable()
export class CompanyBillingSettingRepository implements ICompanyBillingSettingRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyBillingSettingClient')
    public readonly companyBillingSettingClient: ICompanyBillingSettingClient,
  ) {}

  public async post(input: Omit<IPostInput, 'token'>): Promise<IPostResponse> {
    return await this.companyBillingSettingClient.post({
      ...input,
      token: this.companyUserToken.get() || '',
    });
  }
}
