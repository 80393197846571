import styled from '@emotion/styled';
import { useImageUploader } from './useImageUploader';
import { ImageClip } from 'ui/components/company/features/ImageClip/ImageClip';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import ImageIconOriginal from '@mui/icons-material/Image';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 5px;
`;

const Preview = styled.div`
  width: 40%;
  text-align: center;
`;

const ImageIcon = styled(ImageIconOriginal)`
  width: 90px;
  height: 90px;
  opacity: 0.6;
`;

const Button = styled.div`
  flex: 1;
`;

const Label = styled.label`
  padding: 8px 20px;
  border: 2px solid var(--gray-light-2);
  border-radius: 8px;
  background-color: var(--spr-white);
  color: var(--spr-black);
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
`;

const Img = styled.img`
  width: 200px;
  border-radius: 4px;
`;

export interface ImageUploaderProps {
  imagePath: string;
  directory: 'profile' | 'company' | 'project';
  fileName: string;
  fileCategory: 'user' | 'company';
  disabled?: boolean;
  width?: number;
  height?: number;
  aspectRatio?: number;
  withPreview?: boolean;
  placeholder?: 'icon' | 'image';
  urlId?: string | null;
  isSquare?: boolean;
  onComplete: (url: string) => void;
}

/**
 * 画像のアップロードとプレビュー表示
 * @deprecated SelfServeにて、features/ImageUpload としてリニューアル
 */
export const ImageUploader: React.FC<ImageUploaderProps> = (props) => {
  const { newImagePath, imgBase64, uploading, onInput, onClip, createModal, closeModal } =
    useImageUploader(props);

  return (
    <Wrapper>
      {props.withPreview && (
        <Preview>
          {props.placeholder === 'image' && props.imagePath === '' ? <ImageIcon /> : null}
          {props.placeholder === 'icon' && props.imagePath === '' ? <UserIcon size={75} /> : null}
          {props.imagePath !== '' ? (
            props.placeholder === 'image' ? (
              <Img src={newImagePath ?? props.imagePath} />
            ) : (
              <UserIcon src={newImagePath ?? props.imagePath} size={75} />
            )
          ) : null}
        </Preview>
      )}
      <Button>
        {uploading ? (
          'uploading...'
        ) : (
          <Label htmlFor={props.fileName}>
            ファイルを選択
            <input
              id={props.fileName}
              hidden
              type="file"
              accept="image/*"
              disabled={props.disabled}
              onChange={onInput}
            />
          </Label>
        )}
      </Button>
      {createModal(
        <ImageClip
          imageBase64={imgBase64}
          aspectRatio={props.aspectRatio}
          onClip={onClip}
          isSquare={props.isSquare}
          closeModal={closeModal}
        />,
      )}
    </Wrapper>
  );
};
