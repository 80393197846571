import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { CompanySearchResult } from 'domain/entities/CompanySearchResult/CompanySearchResult';
import { useDebounce } from 'application/hooks/useDebounce';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { ICompanySearchQuery } from 'application/querySchemas/ICompanySearchQuery';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { FormSelect } from 'ui/components/company/elements/FormSelect/FormSelect';
import { PREFECTURE_CODES } from 'utils/prefectures';
import { toHankaku } from 'utils/string';
import { Spinner } from 'ui/components/company/elements/Spinner/Spinner';
import { mqUser } from 'utils/responsive';

const prefectureOptions = PREFECTURE_CODES.map((prefecture) => ({
  value: prefecture.code,
  label: prefecture.name,
}));

const Wrapper = styled.div`
  padding: 40px;
  ${mqUser('SP')} {
    padding-bottom: 60px;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: var(--font-xxx-large);
  line-height: 1.2;
  font-weight: var(--font-bold);
  text-align: center;
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: min(200px, 20vw) min(512px, 50vw);
  gap: 8px;
  ${mqUser('SP')} {
    grid-template-columns: min(512px, 60vw);
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Names = styled.ul`
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  max-height: 400px;
  margin: 0;
  padding: 12px;
  background-color: var(--gray-light-3);
  list-style-type: none;
  overflow-y: auto;
`;

const Name = styled.li`
  padding: 11px;
  font-size: var(--font-medium);
  cursor: pointer;
  :hover {
    background-color: var(--gray-light-2);
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray);
  }
`;

const Address = styled.p`
  margin: 4px 0 0;
  font-size: var(--font-default);
  color: var(--gray-dark-4);
  ${mqUser('SP')} {
    line-height: 1.2;
  }
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  height: 20px;
`;

interface Props {
  onSelect: (company: CompanySearchResult) => void;
  closeModal: () => void;
}

/**
 * 企業検索モーダル
 */
export const CompanyNameSearch: React.FC<Props> = ({ onSelect, closeModal }) => {
  const diContainer = useContext(diContainerContext);
  const companySearchQuery = diContainer.resolve<ICompanySearchQuery>('ICompanySearchQuery');

  const [isLoading, setIsLoading] = useState(false);
  /** 検索条件 */
  const [params, setParams] = useState<{ query: string; address: string }>({
    query: '',
    address: '',
  });
  /** 検索結果 */
  const [companies, setCompanies] = useState<CompanySearchResult[]>([]);

  /** リクエスト順序の制御 */
  const lastRequestSentAt = useRef(0);

  const debouncedParams = useDebounce(params, 700);

  const onClickItem = useCallback((company: CompanySearchResult) => {
    onSelect(company);
    closeModal();
  }, []);

  const searchByNumber = useCallback(async (companyNumber: string) => {
    const now = Date.now();
    lastRequestSentAt.current = now;

    setIsLoading(true);
    try {
      const { companies } = await companySearchQuery.searchCompanyByNumber({ companyNumber });
      if (now === lastRequestSentAt.current) {
        setCompanies(companies);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const searchByName = useCallback(async (companyName: string, address: string | null) => {
    const now = Date.now();
    lastRequestSentAt.current = now;

    setIsLoading(true);
    try {
      const { companies } = await companySearchQuery.searchCompanyByName({ companyName, address });
      if (now === lastRequestSentAt.current) {
        setCompanies(companies);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const query = toHankaku(debouncedParams.query);

    if (query.trim() === '') {
      setCompanies([]);
      return;
    }

    // 13桁の数字の場合は法人番号検索
    if (query.length === 13 && query.match(/^[0-9]+$/)) {
      searchByNumber(query);
    } else {
      searchByName(query, debouncedParams.address === '' ? null : debouncedParams.address);
    }
  }, [JSON.stringify(debouncedParams)]);

  return (
    <Wrapper>
      <Title>企業名を検索</Title>
      <Layout>
        <FormSelect
          options={prefectureOptions}
          value={params.address}
          placeholder="都道府県を選択"
          onChange={(e) => setParams({ ...params, address: e.target.value })}
        />
        <InputWrapper>
          <FormInput
            placeholder="企業名か13桁の法人番号を入力"
            value={params.query}
            onChange={(e) => setParams({ ...params, query: e.target.value })}
          />
          {companies.length > 0 ? (
            <Names>
              {companies.map((company) => (
                <Name key={company.number} onClick={() => onClickItem(company)}>
                  {company.name}
                  <Address>{company.address}</Address>
                </Name>
              ))}
            </Names>
          ) : null}

          <SpinnerWrapper>
            {isLoading && <Spinner size="20px" color="var(--gray)" />}
          </SpinnerWrapper>
        </InputWrapper>
      </Layout>
    </Wrapper>
  );
};
