import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { messageContext } from 'application/contexts/useMessage';
import { CHAT_TYPE } from 'domain/entities/ChatRoom/ChatRoom';
import { ChatRoomQuery } from 'interfaceAdapter/queries/ChatRoom';
import { MessageQuery } from 'interfaceAdapter/queries/Message';
import { MessageRepository } from 'interfaceAdapter/repositories/Message';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useRoomDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const diContainer = useContext(diContainerContext);
  const chatRoomQuery = diContainer.resolve(ChatRoomQuery);
  const messageQuery = diContainer.resolve(MessageQuery);
  const messageRepository = diContainer.resolve(MessageRepository);
  const { user } = useContext(authContext);
  const {
    room,
    setReplyRequiredIds,
    messages,
    setMessages,
    members,
    meetRequests,
    fetchMeetRequests,
    openRoom,
    closeRoom,
    setMembers,
    setMeetRequests,
  } = useContext(messageContext);
  const messagesRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [canMessage, setCanMessage] = useState(false);

  const backToList = useCallback(() => {
    navigate('/user/chat/rooms/');
  }, []);

  const scrollToBottom = () => {
    if (!messagesRef.current) return;
    messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
  };

  const onInputFocus = useCallback(() => scrollToBottom(), []);

  let latestTimestamp = 0;
  const onMessageSent = useCallback(async () => {
    if (!room) return;
    const res = await messageQuery.getMessages(room.room_id);
    if (res.length > 0 && latestTimestamp !== res[0].timestamp) {
      setMessages((state) => [...state, res[0]]);
      latestTimestamp = res[0].timestamp;
    }
    // ｢返信待ちのメッセージ｣件数を更新
    const chatRoomsStats = await chatRoomQuery.getChatRoomsStats();
    setReplyRequiredIds(chatRoomsStats.reply_required_room_ids);
  }, [room]);

  useEffect(() => {
    if (params.roomId) {
      openRoom(params.roomId);
      messageQuery.getMessages(params.roomId).then((res) => setMessages(res.reverse()));
      chatRoomQuery.getMembers(params.roomId).then((res) => setMembers(res));
      setTimeout(() => {
        setIsOpen(true);
      }, 200);
    }
    return () => {
      setIsOpen(false);
      closeRoom();
    };
  }, [params.roomId]);

  useEffect(() => {
    if (!room) return;
    setCanMessage(!room.project || room.project?.company?.isActiveCompany() === true);
    if (room.chat_type === CHAT_TYPE.MEET_REQUEST) {
      fetchMeetRequests(room.room_id);
    } else {
      setMeetRequests([]);
    }
  }, [room?.room_id]);

  // メッセージのフェッチ後に最下部（最新メッセージ箇所）にスクロールするようにしている
  useEffect(() => {
    scrollToBottom();
  }, [params.roomId, messages]);

  return {
    user,
    room,
    isOpen,
    messages,
    members,
    meetRequests,
    canMessage,
    sendMessage: messageRepository.sendMessage,
    onInputFocus,
    onMessageSent,
    messagesRef,
    backToList,
  };
};
